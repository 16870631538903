import React, {
	useContext,
	useEffect,
	useReducer,
	useState
} from 'react';
import DatePicker from 'react-datepicker';

import {
	Button,
	Col,
	Form,
	Modal,
	Row,
	Stack,
} from 'react-bootstrap';
import { toast } from 'react-toastify';
import { Store } from '../../Store.js';


import { SkeletonText } from '@chakra-ui/react';
import {
	DirectionsRenderer,
	GoogleMap,
	InfoWindow,
	Marker,
	useJsApiLoader,
} from '@react-google-maps/api';
import axios from 'axios';
import moment from 'moment';
import Carousel from 'react-bootstrap/Carousel';
import Geocode from 'react-geocode';
import { useNavigate } from 'react-router-dom';
import {
	calculateDirectionsResponseUTILS,
	preasignDelivery,
	recalculateRoute,
} from '../../mapsUtils.js';
import {
	GoogleMapsApiKey,
	getError,
	getStatus,
} from '../../utils.js';
import LoadingBox from '../LoadingBox.js';
import RouteEdition from '../RouteEdition/RouteEdition.jsx';
import Stepper from '../Stepper/Stepper.jsx';
import AccordionRoutes from './AccordionRoutes.jsx';
import './GenerateRoutes.css';
import './TablePendingShimpents.jsx';
import TablePendingShipments from './TablePendingShimpents.jsx';
import TableVehicleDelivery from './TableVehicleDelivery.jsx';

const reducer = (state, action) => {
	switch (action.type) {
	case 'FETCH_SHIPMENTS_REQUEST':
		return { ...state, loadingShipments: true };
	case 'FETCH_SHIPMENTS_SUCCESS':
		return {
			...state,
			pendingShipments: action.payload,
			loadingShipments: false,
		};
	case 'FETCH_SHIPMENTS_FAIL':
		return { ...state, loadingVehicles: false, error: action.payload };
	case 'FETCH_VEHICLES_REQUEST':
		return { ...state, loadingVehicles: true };
	case 'FETCH_VEHICLES_SUCCESS':
		return {
			...state,
			vehicles: action.payload,
			loadingVehicles: false,
		};
	case 'FETCH_VEHICLES_FAIL':
		return { ...state, loadingShipments: false, error: action.payload };

		// case "FETCH_FAIL":
		//   return { ...state, fail: false, error: action.payload };
	case 'FETCH_MAP_REQUEST':
		return { ...state, loadingMap: true };
	case 'FETCH_MAP_SUCCESS':
		return { ...state, aMap: action.payload, loadingMap: false };
	case 'FETCH_MAP_FAIL':
		return { ...state, loadingMap: false, error: action.payload };
		//#region GOOGLE API ACTIONS
	case 'SET_AUTOCOMPLETE':
		return { ...state, autocomplete: action.payload };
	case 'SET_DESTINATION':
		return { ...state, destination: action.payload };
	case 'SET_DESTINATION_MARKER':
		return { ...state, destinationMarker: action.payload };
	case 'SET_MAP':
		return { ...state, map: action.payload };

	case 'SET_ADDRESS':
		return { ...state, address: action.payload };
		//#endregion
	case 'CREATE_REQUEST':
		return { ...state, loadingCreate: true };
	case 'CREATE_SUCCESS':
		return {
			...state,
			loadingCreate: false,
		};
	case 'CREATE_FAIL':
		return { ...state, loadingCreate: false };
	case 'SHOW_EDIT_ROUTES':
		return {
			...state,
			editRoutesModal: true,
			routeToEdit: action.payload.route,
			posRouteToEdit: action.payload.index,
		};
	case 'HIDE_EDIT_ROUTES':
		return {
			...state,
			editRoutesModal: false,
			routeToEdit: {},
			posRouteToEdit: null,
		};
	default:
		return state;
	}
};

function GenerateRoutes({ show, onHide }) {
	const { isLoaded } = useJsApiLoader({
		googleMapsApiKey: GoogleMapsApiKey,
		libraries: ['places', 'geometry'],
	});
	Geocode.setApiKey(GoogleMapsApiKey);
	const navigate = useNavigate();

	const [
		{
			loadingCreate,
			loadingShipments,
			pendingShipments,
			loadingVehicles,
			vehicles,
			loadingMap,
			editRoutesModal,
			posRouteToEdit,
		},
		dispatch,
	] = useReducer(reducer, {
		pendingShipments: [],
		loadingShipments: true,
		vehicles: [],
		loadingVehicles: true,
		loadingCreate: false,
		error: '',
		editRoutesModal: false,
		routeToEdit: {},
		posRouteToEdit: null,
		//#region GOOGLE API STATES
		center: {},
		map: null,
		loadingMap: false,
		//#endregion
	});
	const { state, dispatch: ctxDispatch } = useContext(Store);
	const { userInfo } = state;

	//components
	const [selectedShipments, setSelectedShipments] = useState([]);
	const handleSelectedShipmentsChange = (selectedShipments) => {
		setSelectedShipments(selectedShipments);
	};
	const [selectedVehicles, setSelectedVehicles] = useState([]);
	const handleSelectedVehiclesChange = (selectedVehicles) => {
		setSelectedVehicles(selectedVehicles);
	};


	//#region ROUTE DATA
	const [routeDate, setRouteDate] = useState(new Date());
	const [loadingData, setLoadingData] = useState(false);
	const [loadingUpdate, setLoadingUpdate] = useState(false);

	//#endregion

	//#region MAP DATA
	const [originMarker, setOriginMarker] = useState({
		lat: null,
		lng: null,
		show: false,
	});
	const [center, setCenter] = useState({});
	//const [vehicles, setVehicles] = useState([]);
	const [shipmentsWithVehicles, setShipmentsWithVehicles] = useState([]);
	const [routesArrays, setRoutesArrays] = useState([]);
	const [activeMarker, setActiveMarker] = useState(null);
	const [showDirections, setShowDirections] = useState([]);

	const toggleDirectionVisibility = (index) => {
		const updatedShowDirections = [...showDirections];
		updatedShowDirections[index] = !updatedShowDirections[index];
		setShowDirections(updatedShowDirections);
	};

	//#endregion 
	useEffect(() => {
		if (!userInfo || (userInfo.isAdmin && !userInfo.isSuperAdmin)) {
			navigate('/');
			return;
		}
		if (show) {
			if (userInfo.isSuperAdmin) {
				fetchShipments();
				fetchDataMap();
			}
		}
	}, [show]);
	//#region
	const hideModal = () => {
		onHide();
		resetInfo();
	};
	const resetInfo = () => {
		setActiveStep(1);
		setRouteDate(new Date());
	};
	//#region REQUESTS
	const fetchShipments = async () => {
		dispatch({ type: 'FETCH_SHIPMENTS_REQUEST' });
		try {
			const result = await axios.get('/api/orders/pendingByDate', {
			  headers: {
					Authorization: `Bearer ${userInfo ? userInfo.token : null}`,
			  },
			});
		  
			const promises = result.data.map(async (shipment) => {
			  if (typeof shipment.suggestedVehicle !== 'undefined') {
					shipment.checkable = true;
					shipment.vehicle = shipment.suggestedVehicle;
					shipment.vehicle.asignedDelivery = await preasignDelivery(shipment.vehicle._id);
			  } else {
					shipment.checkable = false;
			  }
		  
			  return shipment;
			});
		  
			const newPendingShipments = await Promise.all(promises);
			setShipmentsWithVehicles(newPendingShipments);

			dispatch({
				type: 'FETCH_SHIPMENTS_SUCCESS',
				payload: newPendingShipments,
			});
			await fetchVehicles();
		} catch (error) {
			dispatch({ type: 'FETCH_FAIL', payload: getError(error) });
			if (getStatus(error) === 401) {
				ctxDispatch({ type: 'USER_SIGNOUT' });
				navigate('/signin');
				toast.error('Sesion expirada. Vuelve a ingresar.');
			} else {
				console.error(error);
				toast.error(getError(error));
			}
		}
	};

	const fetchVehicles = async () => {
		dispatch({ type: 'FETCH_VEHICLES_REQUEST' });
		try {
			const result = await axios.get('/api/vehicles/', {
				headers: {
					Authorization: `Bearer ${userInfo ? userInfo.token : null}`,
				},
			});
			if (result) {
				const promises = result.data.map(async (v) => {
					// Await the result of preasignDelivery for each vehicle
					v.asignedDelivery = await preasignDelivery(v._id);
					return v;
				  });
				
				  // Wait for all promises to resolve using Promise.all
				  const vehiclesWDelivery = await Promise.all(promises);
				
				  // Set the vehicles state
				  dispatch({
					type: 'FETCH_VEHICLES_SUCCESS',
					payload: vehiclesWDelivery,
				});
			}
		} catch (error) {
			dispatch({ type: 'FETCH_VEHICLES_FAIL', payload: getError(error) });
			if (getStatus(error) === 401) {
				ctxDispatch({ type: 'USER_SIGNOUT' });
				navigate('/signin');
				toast.error('Sesion expirada. Vuelve a ingresar.');
			} else {
				console.error(error);
				toast.error(getError(error));
			}
		}
	};

	const fetchDataMap = async () => {
		try {
			dispatch({ type: 'FETCH_MAP_REQUEST' });
			const { data } = await axios.get('/api/maps/mapAccount/defaultMap');
			if (data) {
				setOriginMarker({
					lat: data.latCentral,
					lng: data.lngCentral,
					show: true,
				});
				setCenter({ lat: data.latCentral, lng: data.lngCentral });
			}

			dispatch({ type: 'FETCH_MAP_SUCCESS', payload: data });
		} catch (err) {
			dispatch({ type: 'FETCH_MAP_FAIL', payload: getError(err) });
		}
	};

	//#endregion

	//#region Stepper
	const [activeStep, setActiveStep] = useState(1);
	const steps = [
		{
			counter: 1,
			name: 'Vehiculos',
		},
		{
			counter: 2,
			name: 'Envios',
		},
		{
			counter: 3,
			name: 'Previsaulizar Rutas',
		},
	];

	async function nextStep() {
		if (activeStep < steps.length) {
			if (activeStep === 2) {
				const groupedShipments =
          await groupShipmentsByVehicle(selectedShipments);
				setLoadingData(true);
				const isValid = await generateRoutes(groupedShipments);
				if (isValid) {
					setActiveStep(activeStep + 1);
				} else {
					setActiveStep(activeStep);
				}
			} else {
				setActiveStep(activeStep + 1);
			}
		}
	}

	function previousStep(aStep) {
		if(aStep === 1 ){
			hideModal();
		}
		if (aStep > 1) {
			setActiveStep(aStep - 1);
		} 
	}
	//#endregion

	//#region Ruteo
	const groupShipmentsByVehicle = async (shipments) => {
		// Create an object to store shipments grouped by vehicle._id
		const groupedShipments = {};

		// Iterate through each shipment in the array
		shipments.forEach((shipment) => {
			let vehicleId;
			if (shipment.vehicle) {
				vehicleId = shipment.vehicle._id;
			}

			// If the vehicleId is not yet in the groupedShipments object, create an empty array
			if (!groupedShipments[vehicleId]) {
				groupedShipments[vehicleId] = [];
			}

			// Push the shipment into the corresponding group
			groupedShipments[vehicleId].push(shipment);
		});
		const groupedShipmentsArray = Object.values(groupedShipments);

		const groupedShipmentsWithKey = groupedShipmentsArray.map((group) => {
			let vehicleKey = '';
			if (group[0].vehicle) {
				vehicleKey = group[0].vehicle._id;
			}

			return { vehicleKey, shipments: group };
		});
		return groupedShipmentsWithKey;
	};

	//update vehicle when changed
	const updateVehicleInShipments = async (editableShipment, newVehicle) => {
		setLoadingUpdate(true);
		shipmentsWithVehicles.forEach((shipment, i) => {
			if (shipment._id === editableShipment._id) {
				// Found the item with the specified property and value
				pendingShipments[i].vehicle = newVehicle; // Change the property
				return;
			}
		});

		// Update the state with the modified array
		setShipmentsWithVehicles(shipmentsWithVehicles);
		setLoadingUpdate(false);
	};

	//update vehicle when changed delivery
	// const updateDeliveryOfVehicle = async (editableShipment, newVehicle) => {
	// 	setLoadingUpdate(true);
	// 	shipmentsWithVehicles.forEach((shipment, i) => {
	// 		if (shipment._id === editableShipment._id) {
	// 			//Found the item with the specified property and value
	// 			pendingShipments[i].vehicle = newVehicle; // Change the property
	// 			return;
	// 		}
	// 	});

	// 	//Update the state with the modified array
	// 	setShipmentsWithVehicles(shipmentsWithVehicles);
	// 	setLoadingUpdate(false);
	// };

	async function generateRoutes(shipmentsByVehicleArray) {
		try {
			const coordinateOrigin = new window.google.maps.LatLng(
				originMarker.lat,
				originMarker.lng
			);

			//let newArray = [];
			const promises = shipmentsByVehicleArray.map(
				async (shipmentsByVehicle) => {
					const shipments = shipmentsByVehicle.shipments;
					let vehicleValid = true;
					await shipments.forEach((shipment) => {
						if (!shipment.vehicle) {
							vehicleValid = false;
							toast.error('Debe asignar un vehiculo');
						}
					});
					if (vehicleValid) {
						const results = await calculateDirectionsResponseUTILS(
							shipments,
							coordinateOrigin
						);

						return results;
					} else {
						return false;
					}
				}
			);

			const newArray = await Promise.all(promises);
			setRoutesArrays(newArray);

			// // )
			if (newArray.length > 0 && newArray[0]) {
				setShowDirections(newArray.map(() => true));
				setLoadingData(false);
				return true;
			} else {
				return false;
			}
		} catch (err) {
			console.error(err);
			return false;
		}
	}
	//#endregion

	//#region Save in DB
	async function saveDailyRoutes() {
	
		if (routesArrays.length > 0) {
			dispatch({ type: 'CREATE_REQUEST' });

			try {
				await Promise.all(
					routesArrays.map(async (route) => {
						const dailyRoute = {
							routeDate,
							createdBy: userInfo,
							sortedOrders: route.sortedOrders,
							sortedOrderInfo: route.sortedOrderInfo,
							vehicle: route.vehicle,
							time: route.totalTime,
							distance: route.totalDistance,
							originMarker: originMarker,
							asignedDelivery: route.vehicle.asignedDelivery,
						};

						const { data } = await axios.post(
							'/api/dailyRoutes/createDailyRoute',
							{
								dailyRoute: dailyRoute,
							}
						);


						return data;
					})
				);

				// All requests completed successfully
				dispatch({ type: 'CREATE_SUCCESS' });
				toast.success('Rutas creadas con éxito');
				navigate('/AdminScreen/routes');
			} catch (error) {
				dispatch({ type: 'FETCH_FAIL', payload: getError(error) });
				if (getStatus(error) === 401) {
					ctxDispatch({ type: 'USER_SIGNOUT' });
					navigate('/signin');
					toast.error('Sesion expirada. Vuelve a ingresar.');
				} else {
					console.error(error);
					toast.error(getError(error));
				}
			}
		}
	}
	//#endregion

	const handleRouteFromAccordion = (route, i) => {
		dispatch({
			type: 'SHOW_EDIT_ROUTES',
			payload: {
				route: route,
				index: i,
			},
		}); // Do something with the route in the parent component
	};

	const handleRecalculationRoute = async (
		newSortedOrders,
		indexRouteToModify,
		optimize = true
	) => {
		setLoadingData(true);
		if(!newSortedOrders){
			setShowDirections(routesArrays.map(() => true));
		} else{

			if (newSortedOrders.length === 0) {
				const newArray = [...routesArrays];
				newArray.splice(indexRouteToModify, 1);
				setRoutesArrays(newArray);
				dispatch({ type: 'HIDE_EDIT_ROUTES' });
				previousStep(activeStep);
			} else {
				const coordinateOrigin = new window.google.maps.LatLng(
					originMarker.lat,
					originMarker.lng
				);
				const result = await recalculateRoute(
					routesArrays,
					newSortedOrders,
					indexRouteToModify,
					coordinateOrigin,
					optimize
				);
	
				setRoutesArrays(result);
			}
			//MOSTRAR TODAS LAS RUTAS
			setShowDirections(routesArrays.map(() => true));
		}

		setLoadingData(false);
	};

	if (!isLoaded) {
		return <SkeletonText />;
	}
	return (
		<div>
			{loadingCreate && loadingMap && <LoadingBox></LoadingBox>}
			<Modal
				dialogClassName="contact-details-modal edit"
				size="xl"
				show={show}
				onHide={hideModal}
				animation={true}
			>
				<Modal.Header closeButton>
					<Modal.Title>
						<span className="contact-details-title">GENERAR RUTAS</span>
					</Modal.Title>
				</Modal.Header>
				<Modal.Body className="wizard-wrapper">
					<div className="mb-3 mt-md-4">
						<Stepper steps={steps} activeStep={activeStep} />
						<Carousel
							activeIndex={activeStep - 1}
							controls={false}
							indicators={false}
						>
							<Carousel.Item>
								<div className="mt-2">
									<Form onSubmit={(e) => e.preventDefault()}>
										<Form.Group className="mb-3">
											<Row className="mb-2">
												<Col>
													<Form.Label>
Vehiculos y Repartidores del Dia													</Form.Label>
												</Col>
												<Col sm={4}>
													<Form>
														<DatePicker
															showIcon={true}
															className="datepicker"
															locale="es"
															selected={routeDate}
															onChange={async (e) => {
																setRouteDate(e);
															}}
															dateFormat={'dd/MM/yy'}
															minDate={new Date()}
															popperPlacement="bottom"
															popperModifiers={{
																flip: { behavior: ['bottom'] },
																preventOverflow: { enabled: false },
																hide: { enabled: false },
															}}
														></DatePicker>
													</Form>
												</Col>
											</Row>
											<div>
												
												{/* Listado de vehiculos con checkbox a la izquierda y select con repartidores a la derecha */}

												
												{loadingUpdate || loadingVehicles ? (
													<LoadingBox /> ) : 
													<TableVehicleDelivery
														vehicles={vehicles}
														onSelectedVehiclesChange={handleSelectedVehiclesChange}
											
													/>}
												
											</div>

										</Form.Group>
									</Form>
								</div>
							</Carousel.Item>

							<Carousel.Item>
								<div className="mt-2">
									<Form onSubmit={(e) => e.preventDefault()}>
										<Form.Group className="mb-3">
											<Row className="mb-2">
												<Col>
													<Form.Label>
                          Envios Pendientes ({pendingShipments.length})
													</Form.Label>
												</Col>
												<Col sm={4}>
													<Form>
														<DatePicker
															showIcon={true}
															className="datepicker"
															locale="es"
															selected={routeDate}
															onChange={async (e) => {
																setRouteDate(e);
															}}
															dateFormat={'dd/MM/yy'}
															minDate={new Date()}
															popperPlacement="bottom"
															popperModifiers={{
																flip: { behavior: ['bottom'] },
																preventOverflow: { enabled: false },
																hide: { enabled: false },
															}}
														></DatePicker>
													</Form>
												</Col>
											</Row>
											<div>
												{loadingUpdate || loadingShipments ? (
													<LoadingBox />
												) : (
													<TablePendingShipments
														pendingShipments={shipmentsWithVehicles}
														onUpdateVehicle={(editableShipment, newVehicle) =>
															updateVehicleInShipments(
																editableShipment,
																newVehicle
															)
														}
														onSelectedShipmentsChange={
															handleSelectedShipmentsChange
														}
														vehicles={selectedVehicles}
													/>
												)}
											</div>
											{selectedShipments.length === 0 ?(
												<>
													<p className='m-1' style={{ color: 'red' }}>
												Debe seleccionar al menos un envío para continuar con la creación de rutas.
													</p>
												</>

											) : ''}

										</Form.Group>
									</Form>
								</div>
							</Carousel.Item>

							<Carousel.Item>
								{center && (
									<div>
										<Row className="mb-2">
											<Col>
												<Form.Label>
                        Envios seleccionados ({selectedShipments.length})
												</Form.Label>
											</Col>
											<Col sm={4}>
												<Form>
													<DatePicker
														showIcon={true}
														className="datepicker"
														locale="es"
														selected={routeDate}
														dateFormat={'dd/MM/yy'}
														disabled
													></DatePicker>
												</Form>
											</Col>
										</Row>
										<Row>
											{!loadingData && routesArrays.length > 0 ? (
												<React.Fragment>
													<Col xs={6} className="google-map-container">
														<GoogleMap
															className="px-0"
															center={center}
															zoom={12}
															mapContainerStyle={{
																width: '100%',
																height: '100%',
															}}
															options={{
																zoomControl: true,
																streetViewControl: false,
																mapTypeControl: false,
																fullscreenControl: false,
																scaleControl: true,
															}}
															onLoad={(map) =>
																dispatch({ type: 'SET_MAP', payload: map })
															}
														>
															<Marker
																position={{
																	lat: center.lat,
																	lng: center.lng,
																}}
																label={'0'}
															/>

															{routesArrays.map((r, i) => {
																if (r && r.responseToRenderize) {
																	let color = '#92c0f7';
																	if (r.vehicle && r.vehicle.color) {
																		color = r.vehicle.color;
																	}

																	return !loadingData ? (
																		<div key={i}>
																			{showDirections[i] &&
                                      (posRouteToEdit === null ||
                                        posRouteToEdit === i) && ( // Only render when showDirections[i] is true
																				<DirectionsRenderer
																					directions={r.responseToRenderize}
																					options={{
																						polylineOptions: {
																							strokeColor: color,
																						},
																						suppressMarkers: true,
																					}}
																				/>
																			)}
																			{showDirections[i] &&
                                      (posRouteToEdit === null ||
                                        posRouteToEdit === i) &&
                                      r.responseToRenderize.routes[0].legs.map(
                                      	(step, index) => (
                                      		<Marker
                                      			key={index}
                                      			position={step.end_location}
                                      			label={String(index + 1)}
                                      			onClick={() =>
                                      				setActiveMarker(step)
                                      			}
                                      		/>
                                      	)
                                      )}
																			{activeMarker && (
																				<InfoWindow
																					position={activeMarker.end_location}
																					onCloseClick={() =>
																						setActiveMarker(null)
																					}
																				>
																					<div>
																						<p>{activeMarker.end_address}</p>

																						<p>
																							<strong>
                                              Hora estimada de entrega:{' '}
																								{moment(
																									new Date(
																										activeMarker.estimatedTime
																									)
																								)
																									.utc()
																									.format('HH:mm')}
																							</strong>
																						</p>
																					</div>
																				</InfoWindow>
																			)}
                                    ;
																		</div>
																	) : (
																		<LoadingBox />
																	);
																}
															})}
														</GoogleMap>
													</Col>
													<Col>
														{editRoutesModal ? (
															<RouteEdition
																show={editRoutesModal}
																routesArray={routesArrays}
																index={posRouteToEdit}
																onHide={() =>
																	dispatch({ type: 'HIDE_EDIT_ROUTES' })
																}
																onRecalculateRoute={handleRecalculationRoute}
															/>
														) : (
															<AccordionRoutes
																routesArray={routesArrays}
																showDirection={showDirections}
																toggleVisibility={(i) =>
																	toggleDirectionVisibility(i)
																}
																isList={false}
																onRouteToEdit={handleRouteFromAccordion}
															/>
														)}
													</Col>
												</React.Fragment>
											) : routesArrays.length === 0 ? (
												()=>previousStep

											) : (
												<LoadingBox />
											)}
										</Row>
									</div>
								)}
							</Carousel.Item>
						</Carousel>

						<Stack
							className="mt-2 wizard-contact-button"
							direction="horizontal"
							gap={10}
						>
							<Button
								className="mx-auto"
								onClick={()=> previousStep(activeStep)}
								disabled={editRoutesModal ||activeStep === 0}
							>
              Atras
							</Button>

							<Button
								className="mx-auto wizard-contact-button"
								onClick={activeStep === steps.length ? saveDailyRoutes : nextStep}
								disabled={editRoutesModal || (activeStep ===0 && selectedShipments.length === 0)}
							>
								{activeStep === steps.length ?  'Confirmar Rutas' : 'Siguiente'}
							</Button>
						</Stack>
					</div>
				</Modal.Body>
			</Modal>
		</div>
	);
}
export default GenerateRoutes;
