import axios from 'axios';
import classnames from 'classnames';
import moment from 'moment';
import React, { useContext, useEffect, useReducer, useState } from 'react';
import {
	Button,
	Card,
	Dropdown,
	DropdownButton,
	Form,
	Modal,
	Table,
} from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import { Helmet } from 'react-helmet-async';
import {
	BsCheckCircle,
	BsPencilSquare,
	BsPlusCircle,
	BsTrash,
	BsXCircle,
} from 'react-icons/bs';
import { RiTruckLine } from 'react-icons/ri';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Store } from '../../Store';
import LoadingBox from '../../components/LoadingBox';
import MessageBox from '../../components/MessageBox';
import Pagination from '../../components/Pagination/Pagination.jsx';
import { getError, getStatus, maxitemsPerPage } from '../../utils';
import EditVehicleModal from './EditVehicleModal/EditVehicleModal.jsx';

const reducer = (state, action) => {
	switch (action.type) {
	case 'FETCH_REQUEST':
		return { ...state, loading: true };
	case 'FETCH_SUCCESS':
		return {
			...state,
			vehicles: action.payload,
			loading: false,
			itemQuantity: action.payload.length,
		};
	case 'FETCH_FAIL':
		return { ...state, loading: false, error: action.payload };
	case 'EDIT_REQUEST':
		return { ...state, loading: true };
	case 'EDIT_FAIL':
		return { ...state, loading: false };
	case 'EDIT_SUCCESS':
		return {
			...state,
			loading: false,
		};
	case 'DELETE_REQUEST':
		return { ...state, loadingDelete: true, successDelete: false };
	case 'DELETE_SUCCESS':
		return {
			...state,
			loadingDelete: false,
			successDelete: true,
		};
	case 'DELETE_FAIL':
		return { ...state, loadingDelete: false, successDelete: false };

	case 'DELETE_RESET':
		return { ...state, loadingDelete: false, successDelete: false };
	default:
		return state;
	}
};

function FleetList() {
	const [{ loading, error, vehicles, successDelete, itemQuantity }, dispatch] =
    useReducer(reducer, {
    	vehicles: [],
    	loading: true,
    	error: '',
    	itemQuantity: 0,
    });

	const { state, dispatch: ctxDispatch } = useContext(Store);
	const { userInfo } = state;
	const navigate = useNavigate();

	//MODAL
	const [vehicleToDelete, setVehicleToDelete] = useState(null);
	const [showVehicleModal, setShowVehicleModal] = useState(false);

	const [showModal, setShowModal] = useState(false);
	const handleClose = () => setShowModal(false);
	const handleShow = () => setShowModal(true);

	//pagination
	const [currentPage, setCurrentPage] = useState(1);
	const indexOfLastPost = currentPage * maxitemsPerPage;
	const indexOfFirstPost = indexOfLastPost - maxitemsPerPage;
	let currentPosts = vehicles.slice(indexOfFirstPost, indexOfLastPost);
	//change page
	const onPageChange = (pageNumber) => {
		setCurrentPage(pageNumber);

		currentPosts = vehicles.slice(indexOfFirstPost, indexOfLastPost);
	};

	useEffect(() => {
		const fetchData = async () => {
			try {
				dispatch({ type: 'FETCH_REQUEST' });
				const { data } = await axios.get('/api/vehicles/allVehicles', {
					headers: {
						Authorization: `Bearer ${userInfo ? userInfo.token : null}`,
					},
				});

				dispatch({ type: 'FETCH_SUCCESS', payload: data });
			} catch (error) {
				dispatch({ type: 'FETCH_FAIL', payload: getError(error) });
				if (getStatus(error) === 401) {
					ctxDispatch({ type: 'USER_SIGNOUT' });
					navigate('/signin');
					toast.error('Sesion expirada. Vuelve a ingresar.');
				} else {
					console.error(error);
					toast.error(error.message);
				}
			}
		};
		if (successDelete) {
			dispatch({ type: 'DELETE_RESET' });
		} else {
			fetchData();
		}
	}, [successDelete]);

	async function deleteVehicleHandler() {
		try {
			dispatch({ type: 'DELETE_REQUEST' });
			await axios.put(
				`/api/vehicles/disable/${vehicleToDelete._id}`,
				{
					headers: {
						Authorization: `Bearer ${userInfo ? userInfo.token : null}`,
					},
				}
			);
			dispatch({ type: 'DELETE_SUCCESS' });
			toast.success('Vehiculo Deshabilitado');
			handleClose();
		} catch (error) {
			dispatch({ type: 'DELETE_FAIL' });
			if (getStatus(error) === 401) {
				ctxDispatch({ type: 'USER_SIGNOUT' });
				navigate('/signin');
				toast.error('Sesion expirada. Vuelve a ingresar.');
			} else {
				console.error(error);
				toast.error(error.message);
			}
		}
	}

	async function handleDelete(vehicle) {
		await setVehicleToDelete(vehicle);
		handleShow();	}

	async function editVehicleHandler(vehicle) {
		navigate(`/AdminScreen/editVehicle/${vehicle._id}`);
	}

	async function detailsVehicleHandler(vehicle) {
		navigate(`/AdminScreen/vehicleDetails/${vehicle._id}`);
	}

	const addVehicle = async (vehicleData) => {
		const {
			brand,
			registrationNumber,
			model,
			color,
			shiftStart,
			shiftEnd,
			deliveryTime,
		} = vehicleData;
		try {
			await axios.post(
				'/api/vehicles',
				{
					brand,
					model,
					registrationNumber,
					color,
					shiftStart,
					shiftEnd,
					deliveryTime,
				},
				{
					headers: {
						authorization: `Bearer ${userInfo.token}`,
					},
				}
			);
			dispatch({ type: 'FETCH_VEHICLES_REQUEST' });
			toast.success('Vehículo creado');
			window.location.reload();
		} catch (ex) {
			console.error(ex);
			toast.error(getError(ex));
		}
	};

	return (

		<div>
			<EditVehicleModal
				handleClose={() => setShowVehicleModal(false)}
				show={showVehicleModal}
				submitHandler={addVehicle}
			/>
			{/* CONFIRM MODAL / BAR LOADER / ERRORS*/}
			<Modal size="lg" show={showModal} onHide={handleClose} animation={true}>
				<Modal.Header closeButton>
					<Modal.Title>Deshabilitar vehiculo</Modal.Title>
				</Modal.Header>
				<Modal.Body>Seguro desea deshabilitar el vehiculo?</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleClose}>
            Cancelar
					</Button>
					<Button onClick={deleteVehicleHandler}>Confirmar</Button>
				</Modal.Footer>
			</Modal>

			<Helmet>
				<title>Lista de Vehiculos</title>
			</Helmet>

			{loading ? (
				<Row
					className="d-flex justify-content-center align-items-center spinner-row"
					style={{ minHeight: '100vh' }}
				>
					<LoadingBox className="col-1" />
				</Row>
			) : error ? (
				<MessageBox variant="danger">{error}</MessageBox>
			) : (
				<div
					className={classnames('container', {
						['admin-con']: userInfo && userInfo.isSuperAdmin,
					})}
				>
					<div className="w-100" style={{ overflow: 'visible' }}>
						<h3 className="text-right mt-3 mb-3" style={{ margin: '2.5% 0' }}>
							<RiTruckLine className="fa-md mx-2 mb-1" />
              Gestión de Flota
						</h3>
						<div
							style={{
								display: 'flex',
								justifyContent: 'right',
								alignItems: 'center',
							}}
						>
							<Button
								className="btn btn-dark m-1 fixed-right"
								value="Crear Vehicle"
								onClick={() => setShowVehicleModal(true)}
							>
								<span>
									<BsPlusCircle /> Crear Vehiculo
								</span>
							</Button>
						</div>
						{loading ? (
							<LoadingBox></LoadingBox>
						) : error ? (
							<MessageBox variant="danger">{error}</MessageBox>
						) : (
							<Card>
								<Table responsive size="sm">
									<thead className="align-items-center table-order tHead">
										<th className="tableHeader">Matricula</th>
										<th className=" tableHeader">Marca</th>
										<th className=" tableHeader">Modelo</th>
										<th className=" tableHeader">Horario Inicio</th>
										<th className=" tableHeader">Tiempo de Espera (min)</th>
										<th className=" tableHeader">Color</th>
										<th className=" tableHeader">Activo</th>
										<th className=" tableHeader">Acciones</th>
									</thead>
									<tbody className="align-items-center table-order tableBodyHover">
										{currentPosts.map((vehicle, i) => (
											<tr key={i} className="tHover">
												<td
													className="tableBody"
													onClick={() => detailsVehicleHandler(vehicle)}
													style={{ cursor: 'pointer' }}
												>
													{vehicle.registrationNumber}
												</td>
												<td className=" tableBody">{vehicle.brand}</td>
												<td className=" tableBody">{vehicle.model}</td>
												<td className=" tableBody">
													{moment(new Date(vehicle.shiftStart))
														.utc()
														.format('HH:mm')}
												</td>
												<td className=" tableBody">{vehicle.deliveryTime}</td>
												<td
													className=" tableBody"
													// style={{ textAlign: "center" }}
												>
													<Form.Control
														Value={vehicle.color}
														type="color"
														disabled
													/>
												</td>
												<td className=" tableBody">
													{vehicle.active ? (
														<BsCheckCircle style={{ color: 'green' }} />
													) : (
														<BsXCircle style={{ color: 'red' }} />
													)}
												</td>
												<td className=" tableBody">
													<DropdownButton drop="start" title="" style={{ position: 'static' }}>
														<Dropdown.Item
															onClick={() => editVehicleHandler(vehicle)}
														>
															<BsPencilSquare /> Editar
														</Dropdown.Item>

														<Dropdown.Item
															onClick={() => handleDelete(vehicle)}
														>
															<BsTrash /> Deshabilitar
														</Dropdown.Item>
													</DropdownButton>
												</td>
											</tr>
										))}
									</tbody>
								</Table>
							</Card>
						)}
					</div>

					<Pagination
						className="pagination-bar mt-2"
						totalCount={itemQuantity}
						onPageChange={onPageChange}
						currentPage={currentPage}
						pageSize={maxitemsPerPage}
					></Pagination>
				</div>
			)}
		</div>

	);
}

export default FleetList;
