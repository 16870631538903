import axios from 'axios';
import React, { useContext, useReducer, useState } from 'react';
import {
	Button,
	Card,
	Col,
	Container,
	Form,
	Modal,
	Row,
	Stack
} from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { BsArrowLeftCircle } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Store } from '../Store';
import LoadingBox from '../components/LoadingBox';
import { getError, getStatus } from '../utils';

const reducer = (state, action) => {
	switch (action.type) {
	case 'UPDATE_REQUEST':
		return { ...state, loadingUpdate: true };
	case 'UPDATE_SUCCESS':
		return { ...state, loadingUpdate: false };
	case 'UPDATE_FAIL':
		return { ...state, loadingUpdate: false };

	default:
		return state;
	}
};

function ProfileScreen() {
	const navigate = useNavigate();

	const { state, dispatch: ctxDispatch } = useContext(Store);
	const { userInfo } = state;
	if (!userInfo) {
				 navigate('/'); 
				 return null; 
	  }
	const [user, setUser] = useState(userInfo);
	const [name, setName] = useState(userInfo.name);
	const [email, setEmail] = useState(userInfo.email);
	const [phoneNumber, setPhoneNumber] = useState('');
	const [phoneNumberValid, setPhoneNumberValid] = useState(true);
	const [password, setPassword] = useState('');
	const [oldPassword, setOldPassword] = useState('');

	const [confirmPassword, setConfirmPassword] = useState('');

	const [showModal, setShowModal] = useState(false);
	const handleClose = () => setShowModal(false);
	const handleShow = () => setShowModal(true);

	const [{loadingUpdate}, dispatch] = useReducer(reducer, {
		loadingUpdate: false,
	});


	

	const editUserHandler = async () => {
		if (confirmPassword !== password) {
			//TODO: missing password check
			console.info('missing check');
		} else{
			try {
				dispatch({ type: 'UPDATE_REQUEST' });
				const { data } = await axios.put(
					`/api/users/updateProfile/${userInfo._id}`,
					{
						name,
						email,
						password,
						oldPassword,
					},
					{
						headers: {
							Authorization: `Bearer ${userInfo ? userInfo.token : null}`,
						},
					}
				);
				dispatch({
					type: 'UPDATE_SUCCESS',
				});
				toast.success('Usuario actualizado correctamente');
				ctxDispatch({ type: 'USER_SIGNIN', payload: data });
				
				setUser(data);
				localStorage.setItem('userInfo', JSON.stringify(data));
				navigate('/');
			} catch (error) {
				dispatch({ type: 'UPDATE_FAIL'});
				if (getStatus(error) === 401) {
					ctxDispatch({ type: 'USER_SIGNOUT' });
					navigate('/signin');
					toast.error('Sesion expirada. Vuelve a ingresar.');
				} else {
					toast.error(getError(error));
				}
			}

		}
	};


	const handlePhoneNumberChange = (e) => {
		const value = e.toString();
		if(value){
			setPhoneNumber(value);
			const isValidLength = value.length === 11;
			const startsWith5989 = value.startsWith('5989');
			setPhoneNumberValid(isValidLength && startsWith5989);	  

		}
	};

	return (
		<div>
			<Modal size="lg" show={showModal} onHide={handleClose} animation={true}>
				<Modal.Header closeButton>
					<Modal.Title>Guardar Cambios</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form.Group className="mb-3" controlId="password">
						<Form.Label>Ingrese su contraseña actual</Form.Label>
						<Form.Control
							type="password"
							onChange={(e) => setOldPassword(e.target.value)}
							required
							isInvalid={oldPassword === '' || oldPassword === null}
						></Form.Control>
							 <Form.Control.Feedback type="invalid">
                  Debe ingresar su contraseña
						</Form.Control.Feedback>
					</Form.Group>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleClose}>
            Cancelar
					</Button>
					<Button disabled={oldPassword===''} onClick={editUserHandler}>Confirmar</Button>
				</Modal.Footer>
			</Modal>
			<div
				className="container align-items-center admin-con"
			>

				<Helmet>
					<title>Perfil</title>
				</Helmet>

				{loadingUpdate && <LoadingBox></LoadingBox>}

				<Container fluid>
					<Row className="mt-5 justify-content-center align-items-center">
						<Col md={8} lg={6} xs={12} className='w-100'>
							<div className="borderLine"></div>
							<Card className="shadow">
								<Card.Body>
									<div className="mb-3 mt-md-4">
										<h2 className="fw-bold mb-4 text-uppercase text-align-center ">
Mi Perfil										</h2>
										<div className="mb-3">
											<Form onSubmit={(e) => e.preventDefault()}>
												<Form.Group className="mb-3" controlid="name">
													<Form.Label>Nombre</Form.Label>
													<Form.Control
														type="text"
														required
														defaultValue={user.name}
														onChange={(e) => setName(e.target.value)}
													></Form.Control>
												</Form.Group>
												<Form.Group className="mb-3" controlid="email">
													<Form.Label>E-mail</Form.Label>
													<Form.Control
														type="email"
														required
														defaultValue={user.email}
														onChange={(e) =>
															setEmail(e.target.value.toLowerCase())
														}
													></Form.Control>
												</Form.Group>

												<Form.Group className="mb-3" controlid="email">
													<Form.Label>Telefono</Form.Label>
													<Form.Control
														type="number"
														required={user.userType === 'Repartidor'}
														value={parseInt(phoneNumber ? phoneNumber : '5989')}
														onChange={(e)=> handlePhoneNumberChange(e.target.value)}
														isInvalid={!phoneNumberValid}
													></Form.Control>
													 <Form.Control.Feedback type="invalid">
                  Numero invalido
													</Form.Control.Feedback>
													<small id="phoneHelp" className="form-text text-muted">
               Formato: 5989xxxxxxx
													</small>
												</Form.Group>
												<Form.Group className="mb-3" controlId="password">
													<Form.Label>
														Nueva Contraseña</Form.Label>
													<Form.Control
														type="password"
														onChange={(e) => setPassword(e.target.value)}
														required
													></Form.Control>
												</Form.Group>
												<Form.Group className="mb-3" controlId="confirmPassword">
													<Form.Label>Repetir Contraseña</Form.Label>
													<Form.Control
														type="password"
														onChange={(e) => setConfirmPassword(e.target.value)}
														required
													></Form.Control>
												</Form.Group>


												<Stack direction="horizontal" gap={10}>
													<Button id="goBack" onClick={() => navigate(-1)}>
														<BsArrowLeftCircle /> Atras
													</Button>

													<Button
														type="submit"
														className="mx-auto"
														onClick={handleShow}
														disabled={!phoneNumberValid}
													>
                            Guardar Cambios
													</Button>
												</Stack>
											</Form>
										</div>
									</div>
								</Card.Body>
							</Card>
						</Col>
					</Row>
				</Container>
\			</div>
		</div>
	);
}
export default ProfileScreen;
