import axios from 'axios';
import moment from 'moment';
import React, { useContext, useEffect, useReducer, useState } from 'react';
import { Accordion, Button, Card, Form, OverlayTrigger, Row, Table, Tooltip } from 'react-bootstrap';
import { BsFileEarmarkRichtext } from 'react-icons/bs';
import {
	FaRegEdit, FaRoute
} from 'react-icons/fa';
import { FaRegRectangleXmark, FaRegSquareCheck } from 'react-icons/fa6';
import { FcCancel, FcShipped } from 'react-icons/fc';
import { Link, useNavigate } from 'react-router-dom';
import { Store } from '../Store.js';
import { getError, maxitemsPerPage } from '../utils.js';
import LoadingBox from './LoadingBox.js';
import MessageBox from './MessageBox.js';
import Pagination from './Pagination/Pagination.jsx';




// ...
const reducer = (state, action) => {
	switch (action.type) {
	case 'FETCH_TYPES_REQUEST':
		return { ...state, loadingTypes: true };
	case 'FETCH_TYPES_SUCCESS':
		return {
			...state,
			types: action.payload,
			loadingTypes: false,
		};
	case 'FETCH_TYPES_FAIL':
		return { ...state, loadingTypes: false, error: action.payload };
	}
};

const ShipmentListView = ({
	shipmentList,
	itemQuantity,
	shipmentStatus,
	title,
	isLoading,
	onConfirmChanges,
	onCompleteShipment,
	onEditStatus,
	onDetailsShipment,
	isList
}) => {
	const [{ loadingTypes, error, types }, dispatch] = useReducer(
		reducer,
		{
			loadingTypes: false,
			error: '',
			types: [],
		}
	);


	const { state } = useContext(Store);
	const { userInfo } = state;
	const navigate = useNavigate();
	// const isSmallScreen = useMediaQuery({ query: '(max-width: 990px)' });

	
	//state variables
	const [orderToEdit, setOrderToEdit] = useState(null);
	const [newType, setNewType] = useState(null);
	const [loadingShipments, setLoadingShipments] = useState(isLoading);

    
    
	//pagination PENDING
	const [currentPage, setCurrentPage] = useState(1);
	const indexOfLastPost = currentPage * maxitemsPerPage;
	const indexOfFirstPost = indexOfLastPost - maxitemsPerPage;
	let currentPosts = shipmentList.slice(
		indexOfFirstPost,
		indexOfLastPost
	);

	//change page PENDNG
	const onPageChange = (pageNumber) => {
		setCurrentPage(pageNumber);

		currentPosts = shipmentList.slice(
			indexOfFirstPost,
			indexOfLastPost
		);
	};


	const getTypes = async () => {
		try {
			dispatch({ type: 'FETCH_TYPES_REQUEST' });
			const { data } = await axios.get('/api/typeZoneRelation/types/groupedByAccounts');
			dispatch({ type: 'FETCH_TYPES_SUCCESS', payload: data });
		} catch (err) {
			dispatch({ type: 'FETCH_TYPES_FAIL', payload: getError(err) });
		}
	};


	useEffect(() => {
		getTypes();
		if(shipmentList){
			setLoadingShipments(false);
	
		}
        
	}, [shipmentStatus]);

	
	
	async function handleConfirmChanges(order, type) {
		try{
			await onConfirmChanges(order, type);
			setOrderToEdit(null);
		} catch(error){
			console.erroror(error);
		}
	}
    
	async function cancelChanges() {
		setOrderToEdit(null);
	}
    
	async function completeShipmentHandler(order) {
		await onCompleteShipment(order);
	}
    
	async function editStatusHandler(order, status) {
		await onEditStatus(order, status);
	}

	async function detailsShipmentHandler(order) {
		await onDetailsShipment(order);
	}

	async function handleButtonClick(orderId){
		navigate(`/AdminScreen/routes/${orderId}`);
		
	}



	return (
		<div>
			{loadingShipments && <LoadingBox/>}
		
			<div className="card-container">
				<h6 className="m-3">
					<b>{title} ({itemQuantity})</b>
				</h6>
				<Card className="acc-container">
					{loadingShipments ? (
						<Row className="justify-content-center spinner-row">
							<LoadingBox className="col-1" />
						</Row>
					) :  error ? (
						<MessageBox variant="danger">{error}</MessageBox>
					) : (
						!isList ? (
							<Accordion
								className="bg-transparent ml-5"
								defaultActiveKey={currentPosts.map(
									(debt, i) => i
								)}
								alwaysOpen
							>
								{currentPosts.map((debt, i) => {
									return debt.shipment ? (
										<Card className="mb-2 bg-black" key={i}>
											<Accordion.Item eventKey={i}>
												<Accordion.Header className="table-container" style={{ display: 'flex', alignItems: 'center' }}>
													<div style={{ marginRight: 'auto' }}>
														<Link to={`/AdminScreen/shipments/details/${debt.shipment._id}`}>
															{'Cuenta: ' +
                    (debt.owner ? debt.owner.name : null) +
                    ' | Fecha de entrega: ' +
                    (debt.shipment.shipmentDate
                    	? moment(
                    		new Date(debt.shipment.shipmentDate)
                    	).format('DD/MM/YY')
                    	: 'Antes Posible')}
														</Link>
													</div>
													
												</Accordion.Header>
	
												<Accordion.Body>
													<>
														<p className="mb-1">
															<b>Usuario Responsable: </b>{' '}
															{debt.shipment.createdBy
																? debt.shipment.createdBy.name
																: null}
														</p>
														<p className="mb-1">
															<b>Tipo de Envio: </b>{' '}
															{orderToEdit && debt.shipment._id.toString() === orderToEdit._id.toString() ? (		
																<div style={{ display: 'inline-flex', alignItems: 'center' }}>
																		  <Form.Select
																		className="border border-1"
																		defaultValue={debt.shipment.type._id}
																		onChange={async (e) => {
																			  setNewType(e.target.value);
																		}}
																		  >
																		{loadingTypes ? (
																			  <LoadingBox></LoadingBox>
																		) : types[debt.owner._id] ? (
																			  types[debt.owner._id].map((t) => {
																				return (
																				  <option key={t._id} value={t._id}>
																						{t.name}
																				  </option>
																				);
																			  })
																		) : null}
																		  </Form.Select>
																	  
																
																	<Button
																		onClick={() => handleConfirmChanges(orderToEdit, newType)}
																		className={'mt-1 mb-1 mx-1'}
																	>
																		<FaRegSquareCheck />
	
																	</Button>
																			
																	<Button
																		onClick={() => cancelChanges()}
																		className={'mt-1 mb-1'}
																	>
																		<FaRegRectangleXmark />
																	</Button>
																				
																</div>
																 ) : (
																debt.shipment.type
																	? debt.shipment.type.name
																	: ''
															)}			
														</p>
	
														<p className="mb-1">
															<b>Zona de Destino: </b>{' '}
															{debt.shipment.route.destinationAddress
																? (debt.shipment.route.destinationAddress
																	.zone
																	? (debt.shipment.route.destinationAddress
																		.zone.name
																	): null
																) : null}
														</p>
	
														<p className="mb-1">
															{shipmentStatus === 'Pending' ? 
																<>
																	<b>Vehiculo Sugerido: </b> 
																	{debt.shipment.suggestedVehicle
																		? (debt.shipment.suggestedVehicle.brand +
												' ' +
												debt.shipment.suggestedVehicle.model +
												' - ' +
												debt.shipment.suggestedVehicle
													.registrationNumber
																		): 'Sin Asignar'} 
																</>
																: (
																	<>
																		<b>Vehiculo: </b>
																		{debt.shipment.deliveryVehicle
																			? (debt.shipment.deliveryVehicle.brand +
										' ' +
										debt.shipment.deliveryVehicle.model +
										' - ' +
										debt.shipment.deliveryVehicle
											.registrationNumber
																			): 'Sin Asignar'}
																	</>
																)}
														</p>

														{shipmentStatus === 'Asigned' ? 
															<p>
																<b>Repartidor Asignado: </b>
																{debt.shipment.deliveredBy ? debt.shipment.deliveredBy.name : null}
															</p> : null}
																		
														{userInfo ? (
															<div className="text-end">
																{shipmentStatus === 'Asigned' ? (
																	<>
																		<OverlayTrigger
																			key="top"
																			placement="top"
																			overlay={
																				<Tooltip id={'tooltip-top'}>
											 Ver Ruta
																				</Tooltip>
																			}
																		>
																			<Button 																				className='mx-1'
																				onClick={()=> handleButtonClick(debt.shipment._id)}>
																				<FaRoute />
																			</Button>
																		</OverlayTrigger>
																	</>
																): null}
																{shipmentStatus === 'Pending' || shipmentStatus === 'Asigned' ? (
																	<>

																		<OverlayTrigger
																			key="top"
																			placement="top"
																			overlay={
																				<Tooltip id={'tooltip-top'}>
													  Completar Envio
																				</Tooltip>
																			}
																		>
																			<Button
																				drop="start"
																				title=""
																				onClick={() =>
																					completeShipmentHandler(
																						debt.shipment
																					)
																				}
																			>
																				<FcShipped />
																			</Button>
																		</OverlayTrigger>
																		<OverlayTrigger
																			key="top"
																			placement="top"
																			overlay={
																				<Tooltip id={'tooltip-top'}>
													  Cancelar Envio
																				</Tooltip>
																			}
																		>
																			<Button
																				className='mx-1'
																				disabled={
																			
										  !userInfo.isSuperAdmin
																				}
																				onClick={() =>
																					editStatusHandler(
																						debt.shipment, 'Cancelado'
																					)
																				}
																			>
																				<FcCancel />
																			</Button>
																		</OverlayTrigger> 
																	</>
																) : null}
	
	
																{orderToEdit && debt.shipment._id.toString() === orderToEdit._id.toString() || !userInfo.isAdmin ? (
																	null															) : (
																	<OverlayTrigger
																		key="top"
																		placement="top"
																		overlay={
																			<Tooltip id={'tooltip-top'}>
													  Editar Tipo
																			</Tooltip>
																		}
																	>
																		<Button
																			disabled={
																				debt.shipment.status ===
											'Cancelado' ||
										  !userInfo.isAdmin
																			}
																			onClick={() =>
																				setOrderToEdit(debt.shipment)
																			}
																		>
																			<FaRegEdit />
																		</Button>
																	</OverlayTrigger>
																)}
															</div>
														): null}
													</>
												</Accordion.Body>
											</Accordion.Item>
										</Card>
									) : null;
								})}
							</Accordion>

						) :
							(
								<Table
									striped
									bordered
									hover
									responsive
									className="table-container bg-transparent"
									
								>
									<thead className="align-items-center table-order tHead">
										<tr className="align-items-center table-order text-center">
											<th className="col-lg-1 tableHeader">
												{shipmentStatus === 'Completed' ? 'Fecha de Entrega' : shipmentStatus === 'Cancelled' ? 'Fecha Cancelacion' : null }
											</th>
											<th className="col-lg-1 tableHeader">Cuenta</th>
											<th className="col-lg-1 tableHeader">Tipo</th>
											<th className="col-lg-1 tableHeader">	
												{shipmentStatus === 'Completed' ? 'Recibido' : shipmentStatus === 'Cancelled' ? 'Responsable' : null }
											</th>
											<th className="col-lg-1 tableHeader"></th>
										</tr>
									</thead>

									{currentPosts.map((shipment, i) => {
										return  (
											<tbody className="align-items-center table-order tableBodyHover pointerCursor" key={i}>
												<tr
													id="data"
													key={shipment._id}
													onClick={() =>
														detailsShipmentHandler(shipment)
													}
												>
													<td className="col-lg-1 tableBody">
														{shipmentStatus === 'Completed' ? (
															shipment.deliveredDate
																? moment(
																	new Date(shipment.deliveredDate)
																).format('DD/MM/YY')
																: null
														) : shipmentStatus === 'Cancelled' ? (
															shipment.cancelledDate
																? moment(
																	new Date(shipment.cancelledDate)
																).format('DD/MM/YY')
																: null
														) : null }
													</td>

													<td className="col-lg-1 tableBody">
														{shipment.account
															? shipment.account.name
															: null}
													</td>

													<td className="col-lg-1 tableBody">
														{shipment.type
															? shipment.type.name
															: ''}
													</td>

													<td className="col-lg-1 tableBody">
														{shipmentStatus === 'Completed' ? (
															shipment.recipientName
																? shipment.recipientName
																: '') : 
															(shipmentStatus === 'Cancelled' ? 
																(
																	shipment.cancelledBy
																		? shipment.cancelledBy.name
																		: ''
																):null
															)}
													</td>
													
													<td className="col-lg-1 tableBody">
														<OverlayTrigger
															key="top"
															placement={i === 0 ? 'left' : 'top'}
															overlay={
																<Tooltip id={'tooltip-top'}>
						Ver Detalles
																</Tooltip>
															}
														>
															<Button
																className="seeDetailsButton"
																onClick={() =>
																	detailsShipmentHandler(shipment)
																}
															>
																<BsFileEarmarkRichtext />
															</Button>
														</OverlayTrigger>
													</td>
												</tr>
											</tbody>
										);
									})
									}
								</Table>
							)
						


					)}
				</Card>
				<Pagination
					className="pagination-bar"
					totalCount={itemQuantity}
					onPageChange={onPageChange}
					currentPage={currentPage}
					pageSize={maxitemsPerPage}
				></Pagination>
			</div>



	
		</div>
	);
};

export default ShipmentListView;
