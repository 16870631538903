import axios from 'axios';
import Geocode from 'react-geocode';

export const GoogleMapsApiKey = 'AIzaSyBV8MA9vBxZh4vxfZss39ALhf3O4q7gh7I';
export const regexEmail =
  /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
export const whatsappBaseUrl = 'https://api.whatsapp.com/send?phone=';

export const maxitemsPerPage = 10;


export const getError = (error) => {
	return error.response && error.response.data.message
		? error.response.data.message
		: error.message;
};

export const getStatus = (error) => {
	return error.response && error.response.status
		? error.response.status
		: error.message;
};
Geocode.setApiKey(GoogleMapsApiKey);
/**
 * @param {String} hex Six digit hexadecimal color
 * @param {Number} alpha Number between 0 and 1
 */
export const addAlphaToHex = (hex, alpha) => {
	let convertedColor = hex;
	if (hex.length === 6 && alpha > 0 && alpha <= 1) {
		const hexValues = hex.match(/.{1,2}/g);
		const rgbArray = [
			parseInt(hexValues[0], 16),
			parseInt(hexValues[1], 16),
			parseInt(hexValues[2], 16),
		];
		convertedColor = `rgb(${rgbArray[0]},${rgbArray[1]},${rgbArray[2]},${alpha})`;
	}
	return convertedColor;
};
export async function getAddress(destination) {
	if (validateCoord(destination)) {
		try {
			const { results } = await Geocode.fromLatLng(
				destination.lat,
				destination.lng
			);
			return results;
		} catch (ex) {
			console.error(ex);
		}
	}
}
export const showCurrency = (currency, capital, list) => {
	if (list === 'debts') {
		if (!capital || capital === '' || capital === null) {
			return '';
		} else if (currency === 'UYU') {
			return '$U';
		} else if (currency === 'USD') {
			return 'U$S';
		}
	} else {
		if (currency === 'UYU') {
			return '$U';
		} else if (currency === 'USD') {
			return 'U$S';
		}
	}
};

export const initialPolygonPath = [
	{ lat: -34.958508, lng: -56.176129 },
	{ lat: -34.945119, lng: -56.200336 },
	{ lat: -34.948537, lng: -56.165286 },
];
export const validateCoord = (coord) =>
	coord && coord.lat != null && coord.lng != null;

export async function sendNotification(orderId, recipientNumber, operation){


	const accessToken = 'EAAM4rjnxKEgBO7t3vnDHq3ffSrXmmil656ZA6SiJ5K4MZBJinmBmrPjnf9z2Agmp6JxknnLrKAVNtKK6zN1veTpDVsKFZBxPZCgdWtS7IOEaWCbsU8ogYItnZBmZBwLYZBhsFejuO8dmz4TlFwVXD68uBp4ZB2RXm5ujG8MS0ylacjD4o9fJWoG82U4Rs51gq1iK12CvwfGPPe2ZAbW8a3tTQicV9Wx9ESZCNsPUKQiHp8PDMZD';

	const requestBody = {
		'messaging_product': 'whatsapp',
		'to': recipientNumber,
		'type': 'template',
		'template': {
			   'name': operation === 'Cancel' ? 'envio_cancelado' : (
				operation === 'Complete' ? 'envio_completado' : null
			   ),
			   'language': {
				   'code': 'en_US',
			   },
			   'components': [
				 {
				   'type': 'body',
				   'parameters': [
					   {
						   'type': 'text',
						   'text': orderId
					   }
				   ]
				 }
			   ]
		}
	};

	try {
		const response = await axios.post('https://graph.facebook.com/v18.0/178321058708656/messages', requestBody, {
			headers: {
			  'Authorization': `Bearer ${accessToken}`,
			  'Content-Type': 'application/json'
			}
		  });
		return response;
	} catch (error) {
	  console.error('Error:', error);
	}
};



