import axios from 'axios';
import React, { useContext, useEffect, useReducer, useState } from 'react';
import {
	Button,
	Card,
	Col,
	Container,
	Form,
	InputGroup,
	Modal,
	Row,
	Stack,
} from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import {
	BsArrowLeftCircle,
	BsFillEyeFill,
	BsFillEyeSlashFill,
} from 'react-icons/bs';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Store } from '../../Store';
import LoadingBox from '../../components/LoadingBox';
import { getError, getStatus } from '../../utils';

const reducer = (state, action) => {
	switch (action.type) {
	case 'FETCH_REQUEST':
		return { ...state, loadingFetch: true };
	case 'FETCH_SUCCESS':
		return { ...state, accounts: action.payload, loadingFetch: false };
	case 'FETCH_FAIL':
		return { ...state, loadingFetch: false, errorFetch: action.payload };
	case 'CREATE_REQUEST':
		return { ...state, loadingCreate: true };
	case 'CREATE_SUCCESS':
		return {
			...state,
			loadingCreate: false,
		};
	case 'CREATE_FAIL':
		return { ...state, loadingCreate: false };
	default:
		return state;
	}
};
function AddUser() {
	const navigate = useNavigate();
	const params = useParams();
	const { accountId } = params;

	const { state, dispatch: ctxDispatch } = useContext(Store);
	const { userInfo } = state;

	const [name, setName] = useState('');
	const [email, setEmail] = useState('');
	const [phoneNumber, setPhoneNumber] = useState('');
	const [phoneNumberValid, setPhoneNumberValid] = useState(true);
	const [userType, setUserType] = useState('Vendedor');
	const [account, setAccount] = useState(null);

	const [showModal, setShowModal] = useState(false);
	const handleClose = () => setShowModal(false);
	const handleShow = () => setShowModal(true);

	const [password, setPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');
	const [passwordType, setPasswordType] = useState('password');
	const [repeatPasswordType, setRepeatPasswordType] = useState('password');

	const toggleRepeatPassword = () => {
		if (repeatPasswordType === 'password') {
			setRepeatPasswordType('text');
			return;
		}
		setRepeatPasswordType('password');
	};

	const togglePassword = () => {
		if (passwordType === 'password') {
			setPasswordType('text');
			return;
		}
		setPasswordType('password');
	};

	const [{ loadingFetch, loadingCreate, accounts }, dispatch] = useReducer(
		reducer,
		{
			loadingFetch: true,
			accounts: [],
			loadingCreate: false,
			error: '',
		}
	);

	useEffect(() => {
		const fetchData = async () => {
			dispatch({ type: 'FETCH_REQUEST' });
			try {
				const result = await axios.get('/api/accounts', {
					headers: {
						Authorization: `Bearer ${userInfo ? userInfo.token : null}`,
					},
				});
				dispatch({ type: 'FETCH_SUCCESS', payload: result.data });
			} catch (error) {
				dispatch({ type: 'FETCH_FAIL', payload: getError(error) });
				if (getStatus(error) === 401) {
					ctxDispatch({ type: 'USER_SIGNOUT' });
					navigate('/signin');
					toast.error('Sesion expirada. Vuelve a ingresar.');
				} else {
					console.error(error);
					toast.error(getError(error));
				}
			}
		};
		const fetchAccount = async () => {
			try {
				const result = await axios.get(`/api/accounts/${accountId}`);
				await setAccount(result.data);
			} catch (error) {
				dispatch({ type: 'FETCH_FAIL', payload: getError(error) });
				if (getStatus(error) === 401) {
					ctxDispatch({ type: 'USER_SIGNOUT' });
					navigate('/signin');
					toast.error('Sesion expirada. Vuelve a ingresar.');
				} else {
					console.error(error);
					toast.error(getError(error));
				}
			}
		};
		if (accountId) {
			fetchAccount();
		} else {
			fetchData();
		}
	}, []);

	const addUserHandler = async () => {
		if (password !== confirmPassword) {
			toast.warning('Las contraseñas ingresadas no coinciden');
			setShowModal(false);
		} else {
			if (name && email && password && userType && account) {
				try {
					dispatch({ type: 'CREATE_REQUEST' });
					await axios.post(
						'/api/users/createUser',
						{
							name,
							email,
							password,
							account,
							userType,
							phoneNumber,
						},
						{
							headers: {
								Authorization: `Bearer ${userInfo ? userInfo.token : null}`,
							},
						}
					);
					dispatch({ type: 'CREATE_SUCCESS' });

					toast.success('Usuario creado');
					navigate('/AdminScreen/users');
				} catch (error) {
					if (getError(error).split(' ')[0] === 'E11000') {
						toast.error(
							'El correo ingresado ya se encuentra asociado a un usuario'
						);
					} else {
						dispatch({ type: 'FETCH_FAIL', payload: getError(error) });
						if (getStatus(error) === 401) {
							ctxDispatch({ type: 'USER_SIGNOUT' });
							navigate('/signin');
							toast.error('Sesion expirada. Vuelve a ingresar.');
						} else {
							console.error(error);
							toast.error(getError(error));
						}
					}
				}
			} else {
				toast.error('Error. Todos los campos son requeridos.');
			}
		}
	};

	const handlePhoneNumberChange = (e) => {
		const value = e.toString();
		setPhoneNumber(value);
		const isValidLength = value.length === 11;
		const startsWith5989 = value.startsWith('5989');
		setPhoneNumberValid(isValidLength && startsWith5989);	  
	};

	return (
		<div>
			{loadingCreate && loadingFetch && <LoadingBox></LoadingBox>}
			<Modal size="lg" show={showModal} onHide={handleClose} animation={true}>
				<Modal.Header closeButton>
					<Modal.Title>Crear Usuario</Modal.Title>
				</Modal.Header>
				<Modal.Body>Seguro desea crear usuario</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleClose}>
              Cancelar
					</Button>
					<Button onClick={addUserHandler}>Confirmar</Button>
				</Modal.Footer>
			</Modal>

			<div
				className="container align-items-center admin-con"
			>				<Helmet>
					<title>Crear Usuario</title>
				</Helmet>
				<Container fluid>
					<Row className="mt-5 justify-content-center align-items-center">
						<Col md={8} lg={6} xs={12}>
							<div className="borderLine"></div>
							<Card className="shadow">
								<Card.Body>
									<div className="mb-3 mt-md-4">
										<h2 className="fw-bold mb-4 text-uppercase text-align-center ">
                      Crear Usuario
										</h2>
										<div className="mb-3">
											<Form onSubmit={(e) => e.preventDefault()}>
												<Row>
													<Form.Group className="mb-3" controlid="nam">
														<Form.Label>Nombre</Form.Label>
														<Form.Control
															type="text"
															required
															onChange={(e) => setName(e.target.value)}
														></Form.Control>
													</Form.Group>

												</Row>

												<Row>
													<Col>
														<Form.Group className="mb-3" controlid="email">
															<Form.Label>E-mail</Form.Label>
															<Form.Control
																type="email"
																required
																onChange={(e) =>
																	setEmail(e.target.value.toLowerCase())
																}
															></Form.Control>
														</Form.Group>
													
													</Col>
													<Col>
														<Form.Group className="mb-3" controlid="email">
															<Form.Label>Telefono</Form.Label>
															<Form.Control
																type="number"
																required={userType === 'Repartidor'}
																onChange={(e)=> handlePhoneNumberChange(e.target.value)}
																isInvalid={!phoneNumberValid}
															></Form.Control>
													 <Form.Control.Feedback type="invalid">
                  Numero invalido
															</Form.Control.Feedback>
															<small id="phoneHelp" className="form-text text-muted">
               Formato: 5989xxxxxxx
															</small>
														</Form.Group>
													</Col>
												</Row>

												<Row>
													<Form.Group>

														<Form.Label>Contraseña</Form.Label>
														<InputGroup
															className="mb-3"
															style={{ padding: 0 }}
															controlid="password"
														>
															<Form.Control
																type={passwordType}
																required
																onChange={(e) => setPassword(e.target.value)}
															/>
															<Button
																variant="btn btn--secondary"
																className="eyeButton"
																id="button-addon2"
																onClick={togglePassword}
															>
																{passwordType === 'password' ? (
																	<BsFillEyeSlashFill />
																) : (
																	<BsFillEyeFill />
																)}
															</Button>
														</InputGroup>
													</Form.Group>

												</Row>

												<Row>
													<Form.Group>
														<Form.Label>Repetir Contraseña</Form.Label>
														<InputGroup
															className="mb-3"
															style={{ padding: 0 }}
															controlid="password"
														>
															<Form.Control
																type={repeatPasswordType}
																required
																onChange={(e) => setConfirmPassword(e.target.value)}
															/>
															<Button
																variant="btn btn--secondary"
																className="eyeButton ml-0"
																id="button-addon2"
																onClick={toggleRepeatPassword}
															>
																{repeatPasswordType === 'password' ? (
																	<BsFillEyeSlashFill />
																) : (
																	<BsFillEyeFill />
																)}
															</Button>
														</InputGroup>
													</Form.Group>
												</Row>

												<Row>
													<Col md={8}>
														<Form.Group className="mb-3" controlid="account">
															<Form.Label>
                                Selecciona la cuenta del contacto
															</Form.Label>
															{accountId ? (
																<Form.Control
																	type="text"
																	readOnly
																	defaultValue={account ? account.name : null}
																></Form.Control>
															) : (

																<Form.Select
																	required
																	className="border"
																	onChange={(e) => setAccount(e.target.value)}
																>
																	{account ? (
																		<option key={account._id} value={account._id}>
																			{account.name}
																		</option>
																	) : (
																		<option key={0} value={null}></option>
																	)}
																	{accounts.map((account) => {
																		return (
																			<option
																				key={account._id}
																				value={account._id}
																			>
																				{account.name}
																			</option>
																		);
																	})}
																</Form.Select>
															)}
														</Form.Group>
													</Col>

													<Col>
														<Form.Group className="mb-3" controlid="contact">
															<Form.Label>Tipo de Usuario</Form.Label>
															<Form.Select
																onChange={(e) => setUserType(e.target.value)}
															>
																<option value="Vendedor">Vendedor</option>
																<option value="Gerente">Gerente</option>
																<option value="Repartidor">Repartidor</option>
															</Form.Select>
														</Form.Group>
													</Col>
												</Row>

												<Stack direction="horizontal" gap={10} className="mt-3">
													<Button id="goBack" onClick={() => navigate(-1)}>
														<BsArrowLeftCircle /> Atras
													</Button>

													<Button
														type="submit"
														className="mx-auto"
														onClick={handleShow}
														disabled={!phoneNumberValid}
													>
                            Crear Usuario
													</Button>
												</Stack>
											</Form>
										</div>
									</div>
								</Card.Body>
							</Card>
						</Col>
					</Row>
				</Container>
			</div>
		</div>
	);
}
export default AddUser;
