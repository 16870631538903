import React from 'react';
import {
	FaBuilding,
	FaChartBar,
	FaCog,
	FaDolly,
	FaFileExport,
	FaIdCard,
	FaRegListAlt,
	FaRoute,
} from 'react-icons/fa';
import { FaClockRotateLeft } from 'react-icons/fa6';
import { RiTruckLine } from 'react-icons/ri';
import { TiContacts } from 'react-icons/ti';
import { Link } from 'react-router-dom';
import './AdminNavbarOptions.css';

export default function AdminNavbarOptions({ handleCollapse }) {
	return (
		<ul className="admin-navbar-options">
			<li>
				<Link
					to="/AdminScreen/newShipment"
					onClick={handleCollapse}
					className="nav-link"
				>
					<FaDolly /> <h3>Nuevo Envio</h3>
				</Link>
			</li>

			<li>
				<Link
					to="/AdminScreen/shipments"
					onClick={handleCollapse}
					className="nav-link"
				>
					<FaRegListAlt />
					<h3> Envios</h3>
				</Link>
			</li>

			<li>
				<Link
					to="/AdminScreen/balances"
					onClick={handleCollapse}
					className="nav-link"
				>
					<FaChartBar />
					<h3>Balances</h3>
				</Link>
			</li>
			<li>
				<Link
					to="/AdminScreen/accounts"
					onClick={handleCollapse}
					className="nav-link"
				>
					<FaBuilding />
					<h3>Cuentas</h3>
				</Link>
			</li>
			<li className='mb-2'>
				<Link
					to="/AdminScreen/contacts"
					onClick={handleCollapse}
					className="nav-link"
				>
					<TiContacts />
					<h3>Libreta de Direcciones</h3>
				</Link>
			</li>
			<li>
				<Link
					to="/AdminScreen/usersAdmin"
					onClick={handleCollapse}
					className="nav-link"
				>
					<FaIdCard />
					<h3>Repartidores</h3>
				</Link>
			</li>
			<li>
				<Link
					to="/AdminScreen/fleet-management"
					onClick={handleCollapse}
					className="nav-link"
				>
					<FaRoute />
					<h3>Mapa de reparto</h3>
				</Link>
			</li>
			<li>
				<Link
					to="/AdminScreen/vehicles"
					onClick={handleCollapse}
					className="nav-link"
				>
					<RiTruckLine />
					<h3>Gestión de Flota</h3>
				</Link>
			</li>
			<li>
				<Link
					to="/AdminScreen/routes"
					onClick={handleCollapse}
					className="nav-link"
				>
					<FaClockRotateLeft />

					<h3>Histórico de rutas</h3>
				</Link>
			</li>
			<li>
				<Link
					className="nav-link"
					to="/AdminScreen/reports/search"
					onClick={handleCollapse}
				>
					<FaFileExport />
					<h3>Reportes</h3>
				</Link>
			</li>
			<li>
				<Link
					to="/AdminScreen/Config"
					onClick={handleCollapse}
					className="nav-link"
				>
					<FaCog />
					<h3>Configuracion</h3>
				</Link>
			</li>
		</ul>
	);
}
