import axios from 'axios';
import moment from 'moment';
import React, {
	useContext,
	useEffect,
	useMemo,
	useReducer,
	useState,
} from 'react';
import DatePicker from 'react-datepicker';

import {
	DirectionsRenderer,
	GoogleMap,
	InfoWindow,
	Marker,
	useJsApiLoader,
} from '@react-google-maps/api';
import { Button, Card, Col, Row } from 'react-bootstrap';
import Geocode from 'react-geocode';
import { Helmet } from 'react-helmet-async';
import { FaClockRotateLeft } from 'react-icons/fa6';
import { useNavigate, useParams } from 'react-router-dom';
import { Store } from '../../Store';
import LoadingBox from '../../components/LoadingBox';
import MessageBox from '../../components/MessageBox';
import AccordionRoutes from '../../components/ModalGenerateRoutes/AccordionRoutes';
import { calculateDirectionsResponseUTILS } from '../../mapsUtils';
import { GoogleMapsApiKey, getError } from '../../utils';
import './RoutesList.css';

const reducer = (state, action) => {
	switch (action.type) {
	case 'FETCH_MAP_REQUEST':
		return { ...state, loadingMap: true };
	case 'FETCH_MAP_SUCCESS':
		return { ...state, map: action.payload, loadingMap: false };
	case 'FETCH_MAP_FAIL':
		return { ...state, loadingMap: false, error: action.payload };
	
	case 'FETCH_REL_REQUEST':
		return { ...state, loadingRel: true };
	case 'FETCH_REL_SUCCESS':
		return {
			...state,
			orderRoutesRel: action.payload,
			loadingRel: false,
			orderRoutesItemQuantity: action.payload.length,
		};

	case 'FETCH_COMPLETED_SUCCESS':
		return {
			...state,
			completedShipments: action.payload,
			loading: false,
			completedItemQuantity: action.payload.length,
		};
	case 'FETCH_CANCELLED_SUCCESS':
		return {
			...state,
			cancelledShipments: action.payload,
			loading: false,
			cancelledItemQuantity: action.payload.length,
		};
	case 'UPDATE_SUCCESS':
		return { ...state, loading: false };
	case 'FETCH_FAIL':
		return { ...state, loading: false, loadingRel: false, error: action.payload };

	case 'SHOW_GENERATE_ROUTES':
		return { ...state, generateRoutesModal: true };
	case 'HIDE_GENERATE_ROUTES':
		return { ...state, generateRoutesModal: false };
	default:
		return state;
	}
};

const baseStyle = {
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	padding: '20px',
	borderWidth: 2,
	borderRadius: 2,
	borderColor: '#eeeeee',
	borderStyle: 'dashed',
	backgroundColor: '#fafafa',
	color: '#bdbdbd',
	transition: 'border .3s ease-in-out',
};

function RoutesList() {
	const [
		{ loadingMap, loadingRel, error, successDelete, orderRoutesRel},
		dispatch,
	] = useReducer(reducer, {
		orderRoutesRel: [],
		loadingMap: true,
		loadingRel: true,
		error: '',
	});

	const { isLoaded } = useJsApiLoader({
		googleMapsApiKey: GoogleMapsApiKey,
		libraries: ['places', 'geometry'],
	});
	Geocode.setApiKey(GoogleMapsApiKey);

	const { state } = useContext(Store);
	const { userInfo } = state;
	const navigate = useNavigate();
	const params = useParams();
	

	const [orderId, setOrderId] = useState(params.orderId ? params.orderId : null);
	const [center, setCenter] = useState({});
	const [showDirections, setShowDirections] = useState([]);
	const [activeMarker, setActiveMarker] = useState(null);
	const [activeIndex, setActiveIndex] = useState(null);
	const [selectedRoute, setSelectedRoute] = useState(null);
	const [buttonDetails, setButtonDetails] = useState(true);
	const [iconWarehouse, setIconWarehouse] = useState(null);
	const [iconDelivered, setIconDelivered] = useState(null);

	//state variables

	const [routeDate, setRouteDate] = useState(params.selectedDate ? new Date(params.selectedDate) : new Date());

	useMemo(() => ({ ...baseStyle }), []);

	const loadData = async ()=> {
		await fetchDataMap();
		await fetchIcons();
		
	};

	useEffect(() => {
		if (successDelete) {
			dispatch({ type: 'DELETE_RESET' });
		} else {
			if (userInfo.isAdmin) {	
				loadData();
			} else {
				navigate('/');
			}
		}
	}, [successDelete]);


	const getRelations = async (routeSelectedDate = new Date(), origin, route = null) => {
		try {
			dispatch({ type: 'FETCH_REL_REQUEST' });
			const response = await axios.get(
				`/api/orderDailyRouteRelation/byRoute/${routeSelectedDate}/${userInfo._id}`
			);
				//RECALCULATE ROUTES 
			const coordinateOrigin =  new window.google.maps.LatLng(
				origin.lat,
				origin.lng
			);
			let routesArray = [];
			const promises = response.data.map(async (shArray) => {
				const routeId =  shArray[0].dailyRoute._id;
				const asignedDelivery = shArray[0].dailyRoute.asignedDelivery;
				shArray.sort((a, b) => a.numberInRoute - b.numberInRoute);
				const orderArray = shArray.map((item) => item.order);
				orderArray.map((order)=> {
					order.routeId = routeId;
					order.asignedDelivery = asignedDelivery;
				});
					
				  const results = await calculateDirectionsResponseUTILS(
					orderArray,
					coordinateOrigin,
					false
				  );
				  return results;
			});
				
			// Use Promise.all to wait for all promises to resolve
			try {
				  routesArray = await Promise.all(promises);
				  if(route !== null){
					const routeInArray = routesArray.find(r => r._id === route.dailyRoute._id);
					const indexOfOrder = route.numberInRoute;
					setActiveIndex(indexOfOrder);
					setActiveMarker(routeInArray.responseToRenderize.routes[0].legs[indexOfOrder]);
				  }
			} catch (error) {
				  console.error('Error in Promise.all:', error);
			}
			setShowDirections(response.data.map(() => true));
	
			dispatch({ type: 'FETCH_REL_SUCCESS', payload: routesArray });
		} catch (err) {
			dispatch({ type: 'FETCH_FAIL', payload: getError(err) });
		}

		
	};

	const fetchDataMap = async () => {
		try {
			dispatch({ type: 'FETCH_MAP_REQUEST' });
			const { data } = await axios.get('/api/maps/mapAccount/defaultMap');
			if (data) {
				setCenter({ lat: data.latCentral, lng: data.lngCentral });
			}
			if(orderId){
				const response = await axios.get(
					`/api/orderDailyRouteRelation/byOrder/${orderId}/`
				);
				setSelectedRoute(response.data);
				setRouteDate(new Date(response.data.date));
				const selRoute = response.data;
				await getRelations(new Date(response.data.date), { lat: data.latCentral, lng: data.lngCentral }, selRoute);
			} else {
				await getRelations(routeDate, { lat: data.latCentral, lng: data.lngCentral });

			}
			dispatch({ type: 'FETCH_MAP_SUCCESS', payload: data });
		} catch (err) {
			dispatch({ type: 'FETCH_MAP_FAIL', payload: getError(err) });
		}
	};

	const fetchIcons = async () => {
		try {
			const { data } = await axios.get('/api/mapIcons/isActiveIcon', {});
			data.map((micon) => {
				const icon={
					url: micon.image ? micon.image.fileLink : null,
					scaledSize: new window.google.maps.Size(28, 38), 
				};
				if(micon.label === 'warehouse'){
					setIconWarehouse(icon);
				} else if( micon.label === 'delivered'){
					setIconDelivered(icon);
				}
			});
		} catch (err) {
			console.error(err);
		}
	}; 

	const changeDate = async (newDate) => {
		navigate(`/AdminScreen/routesByDate/${new Date(newDate)}`);
		const resetVariables = async () => {
			setSelectedRoute(null);
			setActiveIndex(null);
			setActiveMarker(null);
			setRouteDate(newDate);
			setOrderId(null);

		};
		await resetVariables();
		await getRelations(newDate, center, selectedRoute) ;
	};

	const viewOrderDetails = async () => {
		
		let idOrder = '';
		if(selectedRoute.order){
			idOrder = orderId;
			
		}else{
			if(selectedRoute.sortedOrders.length> activeIndex){
				idOrder = selectedRoute.sortedOrders[activeIndex]._id;
			}
		}
		 navigate(`/AdminScreen/routes/${idOrder}`);
		if(idOrder !== ''){	
			navigate(`/AdminScreen/shipments/details/${idOrder}`);
		}

	};

	const showDetails = async (r, index) => {
		if(r.order || r.sortedOrders.length > index ){
			setButtonDetails(true);
		}else{
			setButtonDetails(false);
		}
	};

	//#region MODALS

	//#endregion

	const toggleDirectionVisibility = (index) => {
		const updatedShowDirections = [...showDirections];
		updatedShowDirections[index] = !updatedShowDirections[index];
		setShowDirections(updatedShowDirections);
	};

	return (loadingMap || loadingRel) ? (
		<Row
			className="d-flex justify-content-center align-items-center spinner-row"
			style={{ minHeight: '100vh' }}
		>
			<LoadingBox className="col-1" />
		</Row>
	) : error ? (
		<MessageBox variant="danger">{error}</MessageBox>
	) : (
		<>
			<div>
				<Helmet>
					<title>Lista de Rutas</title>
				</Helmet>

				<div className="container admin-con">

					<div className="w-100">
						<h3
							className="text-right mt-3 mb-3 mx-3"
							style={{ margin: '2.5% 1' }}
						>
							<FaClockRotateLeft className="fa-md mx-2 mb-1"/> 
              Histórico de Rutas
						</h3>
						<div className="card-container">
							<Row>
								<Col className="text-end">
									{' '}
									<DatePicker
										className="datepicker"
										showIcon={true}
										locale="es"
										selected={routeDate}
										onChange={async (e) => {
											await changeDate(e);
										}}
										dateFormat={'dd/MM/yy'}
										// minDate={new Date()}
										popperPlacement="bottom-end"
										popperModifiers={{
											flip: { behavior: ['bottom'] },
											preventOverflow: { enabled: false },
											hide: { enabled: false },
										}}
									></DatePicker>
								</Col>
							</Row>

							<Card className="acc-container">
								<Card>
									<Row>
										<AccordionRoutes
											routesArray={orderRoutesRel}
											showDirection={showDirections}
											toggleVisibility={(i) => toggleDirectionVisibility(i)}
											isList={true}
											selectedRoute={selectedRoute}
										/>
									</Row>
									<div className="mt-2">
										{isLoaded && orderRoutesRel.length > 0 ? (
										// Render the map directly
											<Row>
												<React.Fragment>
													<div>
														<Col
															xs={8}
															className="google-map-container-accordion"
														>
															<GoogleMap
																className="px-0"
																center={center}
																zoom={12}
																mapContainerStyle={{
																	width: '100%',
																	height: '100%',
																}}
																options={{
																	zoomControl: true,
																	streetViewControl: false,
																	mapTypeControl: false,
																	fullscreenControl: false,
																	scaleControl: true,
																}}
																onLoad={(map) =>
																	dispatch({
																		type: 'FETCH_MAP_SUCCESS',
																		payload: map,
																	})
																}
															>
																{orderRoutesRel.map((r, i) => {
																	if (r) {
																		let color = '#92c0f7';
																		if (
																			r.vehicle &&
                                      r.vehicle.color
																		) {
																			color = r.vehicle.color;
																		}

																		return (
																			<div key={i}>
																				{showDirections[i] && ( 
																					<DirectionsRenderer
																						directions={
																							r.responseToRenderize
																						}
																						options={{
																							polylineOptions: {
																								strokeColor: color,
																							},
																							suppressMarkers: true,
																						}}
																					/>
																				)}
																				{showDirections[i] &&
                                          r.responseToRenderize.routes[0].legs.map(
                                          	(step, index) => (
                                          		index !== r.responseToRenderize.routes[0].legs.length ? (
                                          			<Marker
                                          				key={index}
                                          				position={step.end_location}
                                          				icon={
                                          					index === r.responseToRenderize.routes[0].legs.length - 1 ? 
                                          						
                                          					iconWarehouse
                                          				 : (
                                          							r.sortedOrders[index] && r.sortedOrders[index].status && r.sortedOrders[index].status === 'Entregado' ? (
                                          								
                                          									iconDelivered
                                          								
                                          							)  : 
                                          								null
                                          						)}
                                          				label={r.sortedOrders[index] && r.sortedOrders[index].status && r.sortedOrders[index].status === 'Asignado' ? String(index + 1) : null}
                                          				onClick={async () => {
                                          					setSelectedRoute(r),
                                          					setActiveMarker(step),
                                          					setActiveIndex(index),
                                          					await showDetails(r, index);
                                          				}
														
                                          				}
                                          			/>

                                          		) : null
                                          	)
                                          )}
																				{activeMarker && (
																					<InfoWindow
																						position={activeMarker.end_location}
																						onCloseClick={() =>
																							setActiveMarker(null)
																						}
																					>
																						<div>
																							<p>{activeMarker.end_address}</p>
																							<p>
																								<strong>
                                                  Hora estimada de entrega:{' '}
																									{moment(
																										new Date(
																											activeMarker.estimatedTime
																										)
																									)
																										.utc()
																										.format('HH:mm')}
																								</strong>
																							</p>
																							{buttonDetails ? (
																								<Button style={{ padding: '5px', fontSize: '10px' }} onClick={()=> {
																									viewOrderDetails();
																								}
																								}>Detalles</Button>

																							) : null}

																							
																						</div>
																					</InfoWindow>
																				)}
																			</div>
																		);
																	}
																})}
																{/* Your map-related JSX content */}
															</GoogleMap>
														</Col>
													</div>
												</React.Fragment>
											</Row>
										) : null}
									</div>
								</Card>
							</Card>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default RoutesList;
