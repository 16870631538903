import axios from 'axios';
import React, { useContext, useEffect, useReducer, useState } from 'react';
import {
	Button,
	Card,
	Col,
	Container,
	FloatingLabel,
	Form,
	Modal,
	OverlayTrigger,
	Row,
	Stack,
	Tooltip,
} from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { BsArrowLeftCircle } from 'react-icons/bs';
import { MdCancel, MdCheckCircle, MdDirectionsCar } from 'react-icons/md';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Store } from '../../Store';
import LoadingBox from '../../components/LoadingBox';
import { getError, getStatus } from '../../utils';

const reducer = (state, action) => {
	switch (action.type) {
	case 'FETCH_CONTACTS_REQUEST':
		return { ...state, loadingContacts: true };
	case 'FETCH_CONTACTS_SUCCESS':
		return { ...state, contacts: action.payload, loadingContacts: false };
	case 'FETCH_CONTACTS_FAIL':
		return { ...state, loadingContacts: false, error: action.payload };
	case 'FETCH_REQUEST':
		return { ...state, loading: true };
	case 'FETCH_SUCCESS':
		return { ...state, user: action.payload, loading: false };
	case 'FETCH_FAIL':
		return { ...state, loading: false, error: action.payload };
	default:
		return state;
	}
};

function EditUser() {
	const params = useParams();
	const navigate = useNavigate();

	const { id } = params;
	const [{ loading, error, user, loadingContacts, contacts }, dispatch] =
    useReducer(reducer, {
    	contacts: [],
    	loadingContacts: true,
    	user: {},
    	loading: true,
    	error: '',
    });
	const { state, dispatch: ctxDispatch } = useContext(Store);
	const { userInfo } = state;
	const [name, setName] = useState('');
	const [email, setEmail] = useState('');
	const [phoneNumber, setPhoneNumber] = useState('');
	const [phoneNumberValid, setPhoneNumberValid] = useState(true);
	const [isAdmin, setIsAdmin] = useState('');
	const [toContact, setToContact] = useState(false);
	const [contact, setContact] = useState('');
	const [vehicle, setVehicle] = useState(null);
	const [vehicles, setVehicles] = useState([]);
	const [editVehicle, setEditVehicle] = useState(false);

	const [showModal, setShowModal] = useState(false);
	const handleClose = () => setShowModal(false);
	const handleShow = () => setShowModal(true);

	useEffect(() => {
		const fetchData = async () => {
			dispatch({ type: 'FETCH_REQUEST' });
			try {
				const result = await axios.get(`/api/users/userById/${id}`);
				dispatch({ type: 'FETCH_SUCCESS', payload: result.data });
				setName(result.data.name);
				setEmail(result.data.email);
				setPhoneNumber(result.data.phoneNumber.replace('+', ''));
				setIsAdmin(result.data.isAdmin);
				setContact(result.data.contact);
				setVehicle(result.data.vehicle);
				setPhoneNumberValid(result.data.phoneNumber !== null);
				if (result.data.isAdmin) {
					await fetchVehicles();
				}
			} catch (error) {
				dispatch({ type: 'FETCH_FAIL', payload: getError(error) });
			}
		};
		const fetchContacts = async () => {
			dispatch({ type: 'FETCH_CONTACTS_REQUEST' });
			try {
				const result = await axios.get('/api/contacts', {
					headers: {
						Authorization: `Bearer ${userInfo ? userInfo.token : null}`,
					},
				});
				dispatch({ type: 'FETCH_CONTACTS_SUCCESS', payload: result.data });
			} catch (error) {
				dispatch({ type: 'FETCH_FAIL', payload: getError(error) });
				if (getStatus(error) === 401) {
					ctxDispatch({ type: 'USER_SIGNOUT' });
					navigate('/signin');
					toast.error('Sesion expirada. Vuelve a ingresar.');
				} else {
					console.error(error);
					toast.error(getError(error));
				}
			}
		};
		fetchContacts();
		fetchData();
	}, [id]);

	const editUserHandler = async () => {
		try {
			dispatch({ type: 'CREATE_REQUEST' });
			const { data } = await axios.put(
				`/api/users/${user._id}`,
				{
					name,
					email,
					phoneNumber,
					isAdmin,
					contact,
					vehicle,
				},
				{
					headers: {
						Authorization: `Bearer ${userInfo ? userInfo.token : null}`,
					},
				}
			);
			dispatch({
				type: 'UPDATE_SUCCESS',
			});
			ctxDispatch({ type: 'USER_UPDATE_SUCCESS', payload: data });
			localStorage.setItem('user', JSON.stringify(data));

			toast.success('Usuario actualizado Correctamente');
			navigate(-1);
	
		} catch (err) {
			dispatch({ type: 'FETCH_FAIL', payload: getError(error) });
			if (getStatus(error) === 401) {
				ctxDispatch({ type: 'USER_SIGNOUT' });
				navigate('/signin');
				toast.error('Sesion expirada. Vuelve a ingresar.');
			} else {
				console.error(error);
				toast.error(getError(error));
			}
		}
	};

	const fetchVehicles = async () => {
		try {
			const result = await axios.get('/api/vehicles/', {
				headers: {
					Authorization: `Bearer ${userInfo ? userInfo.token : null}`,
				},
			});
			if (result) {
				setVehicles(result.data);
			}
		} catch (error) {
			dispatch({ type: 'FETCH_FAIL', payload: getError(error) });
			if (getStatus(error) === 401) {
				ctxDispatch({ type: 'USER_SIGNOUT' });
				navigate('/signin');
				toast.error('Sesion expirada. Vuelve a ingresar.');
			} else {
				console.error(error);
				toast.error(getError(error));
			}
		}
	};

	const changeVehicle = async (vehicleId) => {
		const newVehicle = vehicles.find((v) => vehicleId === v._id);
		setVehicle(newVehicle);
		setEditVehicle(false);
	};

	const handlePhoneNumberChange = (e) => {
		const value = e.toString();
		setPhoneNumber(value);
		const isValidLength = value.length === 11;
		const startsWith5989 = value.startsWith('5989');
		setPhoneNumberValid(isValidLength && startsWith5989);	  
	};

	return (
		<div>
			{(loading || loadingContacts) && <LoadingBox></LoadingBox>}
			{/* CONFIRM MODAL / BAR LOADER / ERRORS*/}
			<Modal size="lg" show={showModal} onHide={handleClose} animation={true}>
				<Modal.Header closeButton>
					<Modal.Title>Guardar Cambios</Modal.Title>
				</Modal.Header>
				<Modal.Body>Seguro desea guardar los cambios?</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleClose}>
            Cancelar
					</Button>
					<Button onClick={editUserHandler}>Confirmar</Button>
				</Modal.Footer>
			</Modal>

			<div
				className="container align-items-center admin-con"
			>
				<Helmet>
					<title>Editar Usuario</title>
				</Helmet>
				<Container fluid>
					<Row className="mt-5 justify-content-center align-items-center">
						<Col md={8} lg={6} xs={12}>
							<div className="borderLine"></div>
							<Card className="shadow">
								<Card.Body>
									<div className="mb-3 mt-md-4">
										<h2 className="fw-bold mb-4 text-uppercase text-align-center ">
											{user.isAdmin ? (user.userType === 'Repartidor' ? 'Editar Repartidor' : 'Editar Administrador') : 'Editar Usuario'}
										</h2>
										<div className="mb-3">
											<Form onSubmit={(e) => e.preventDefault()}>
												<Form.Group className="mb-3" controlid="name">
													<Form.Label>Nombre</Form.Label>
													<Form.Control
														type="text"
														required
														defaultValue={user.name}
														onChange={(e) => setName(e.target.value)}
													></Form.Control>
												</Form.Group>
												<Form.Group className="mb-3" controlid="email">
													<Form.Label>E-mail</Form.Label>
													<Form.Control
														type="email"
														required
														defaultValue={user.email}
														onChange={(e) =>
															setEmail(e.target.value.toLowerCase())
														}
													></Form.Control>
												</Form.Group>

												<Form.Group className="mb-3" controlid="email">
													<Form.Label>Telefono</Form.Label>
													<Form.Control
														type="number"
														required={user.userType === 'Repartidor'}
														value={parseInt(phoneNumber ? phoneNumber : '5989')}
														onChange={(e)=> handlePhoneNumberChange(e.target.value)}
														isInvalid={!phoneNumberValid}
													></Form.Control>
													 <Form.Control.Feedback type="invalid">
                  Numero invalido
													</Form.Control.Feedback>
													<small id="phoneHelp" className="form-text text-muted">
               Formato: 5989xxxxxxx
													</small>
												</Form.Group>

												{user.isAdmin ? (
													<Form.Group controlId="vehicle">
														<Form.Label>Vehiculo</Form.Label>
														<Row className="mb-4">
															{editVehicle ? (
																<>
																	<Col xs={9}>
																		<Form.Select
																			className="border border-2 rounded mb-0"
																			onChange={async (e) => {
																				setVehicle(e.target.value);
																			}}
																		>
																			{user.vehicle ?  (

																				<option key={user.vehicle._id} value={user.vehicle._id}>
																					{user.vehicle.brand +
                                            ' ' +
                                            user.vehicle.model +
                                            ' - ' +
                                            user.vehicle.registrationNumber}
																				</option>
																			) : (

																				<option key={null} value={null}>
											Sin Asignar
																				</option>
																			)}
																			{vehicles.map((vehicle) => (
																				<option
																					key={vehicle._id}
																					value={vehicle._id}
																				>
																					{vehicle.brand +
                                            ' ' +
                                            vehicle.model +
                                            ' - ' +
                                            vehicle.registrationNumber}
																				</option>
																			))}
																		</Form.Select>
																	</Col>
																	<Col>
																		<OverlayTrigger
																			key="top"
																			placement="top"
																			overlay={
																				<Tooltip id={'tooltip-top'}>
                                          Asignar Vehiculo
																				</Tooltip>
																			}
																		>
																			<Button
																				className="btn-sm p-0 pt-1 mx-1 mt-1"
																				id="asignar"
																				onClick={async () => {
																					await changeVehicle(vehicle);
																				}}
																			>
																				<MdCheckCircle className="fa-lg mx-2" />
																			</Button>
																		</OverlayTrigger>
																		<OverlayTrigger
																			key="top"
																			placement="top"
																			overlay={
																				<Tooltip id={'tooltip-top'}>
                                          Cancelar
																				</Tooltip>
																			}
																		>
																			<Button
																				className="btn-sm p-0 pt-1 mx-1 mt-1"
																				id="cancelar"
																				onClick={async () => {
																					setVehicle(user.vehicle);
																					setEditVehicle(false);
																				}}
																			>
																				<MdCancel className="fa-lg mx-2" />
																			</Button>
																		</OverlayTrigger>
																	</Col>
																</>
															) : (
																<>
																	<Col xs={9}>
																		<Form.Control
																			type="text"
																			value={
																				vehicle
																					? vehicle.brand +
                                            ' ' +
                                            vehicle.model +
                                            ' - ' +
                                            vehicle.registrationNumber
																					: 'Sin Asignar'
																			}
																			disabled
																		></Form.Control>
																	</Col>
																	<Col>
																		<OverlayTrigger
																			key="top"
																			placement="top"
																			overlay={
																				<Tooltip id={'tooltip-top'}>
                                          Editar Vehiculo
																				</Tooltip>
																			}
																		>
																			<Button
																				className="btn-sm p-0 pt-1"
																				id="editvehicle"
																				onClick={async () => {
																					setEditVehicle(true);
																				}}
																			>
																				<MdDirectionsCar className="fa-lg mx-2" />
																			</Button>
																		</OverlayTrigger>
																	</Col>
																</>
															)}
														</Row>
													</Form.Group>
												) : (
													<Row>
														<Col>
															<Form.Group
																className="my-3 border border-1 p-1"
																controlId="psw"
															>
																<Form.Label>Es Administrador?</Form.Label>
																<Form.Check
																	type="checkbox"
																	onChange={(e) => setIsAdmin(e.target.value)}
																></Form.Check>
															</Form.Group>
														</Col>
														<Col md={8}>
															{user.contact === null ? (
																<Form.Group className="my-3 border border-1 p-1">
																	<Form.Label>Asociar a Contacto</Form.Label>
																	<Form.Check
																		type="checkbox"
																		onChange={() => setToContact(!toContact)}
																	></Form.Check>
																</Form.Group>
															) : (
																<Form.Group className="my-3 p-1">
																	<FloatingLabel
																		controlId="floatingInput"
																		label="Contacto al que desea aosciar"
																		className="mb-2 pb-2"
																	>
																		<Form.Select
																			className="mt-2"
																			defaultValue={contact}
																			onChange={(e) =>
																				setContact(e.target.value)
																			}
																		>
																			<option
																				key={0}
																				value={
																					user.contact ? user.contact._id : null
																				}
																			>
																				{user.contact
																					? user.contact.name
																					: 'No asociar a un contacto'}
																			</option>
																			{contacts.map((contact) => {
																				return (
																					<option
																						key={contact._id}
																						value={contact._id}
																					>
																						{contact.name}
																					</option>
																				);
																			})}
																			{user.contact ? (
																				<option key={0} value={null}>
                                          No asociar a un contacto
																				</option>
																			) : null}
																		</Form.Select>
																	</FloatingLabel>
																</Form.Group>
															)}

															{toContact ? (
																<Row>
																	<Form.Group>
																		<FloatingLabel
																			controlId="floatingInput"
																			label="Selecciona el contacto al que desea aosciar"
																			className="mb-1"
																		>
																			<Form.Select
																				defaultValue={user.contact}
																				onChange={(e) =>
																					setContact(e.target.value)
																				}
																			>
																				<option key={0} value={null}>
                                          No asociar a un contacto
																				</option>
																				{contacts.map((contact) => {
																					return (
																						<option
																							key={contact._id}
																							value={contact._id}
																						>
																							{contact.name}
																						</option>
																					);
																				})}
																			</Form.Select>
																		</FloatingLabel>
																	</Form.Group>
																</Row>
															) : null}
														</Col>
													</Row>
												)}

												<Stack direction="horizontal" gap={10}>
													<Button id="goBack" onClick={() => navigate(-1)}>
														<BsArrowLeftCircle /> Atras
													</Button>

													<Button
														type="submit"
														className="mx-auto"
														onClick={handleShow}
														disabled={editVehicle || !phoneNumberValid}
													>
                            Guardar Cambios
													</Button>
												</Stack>
											</Form>
										</div>
									</div>
								</Card.Body>
							</Card>
						</Col>
					</Row>
				</Container>
			</div>
		</div>
	);
}
export default EditUser;
