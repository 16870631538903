import React from 'react';
import { FaChartBar, FaDolly, FaRegListAlt } from 'react-icons/fa';
import { FaClockRotateLeft } from 'react-icons/fa6';
import { TiContacts } from 'react-icons/ti';
import { Link } from 'react-router-dom';
import './NavbarOptions.css';

export default function NavbarOptions({ handleCollapse, userInfo}) {
	return (
		<ul className="admin-navbar-options">
			{!userInfo ? (
				<li>
					<Link to="/newShipment" onClick={handleCollapse} className="nav-link">
						<FaDolly /> <h3>Nuevo Envio</h3>
					</Link>
				</li>
			) : userInfo && !userInfo.isAdmin ? (
				<>
					<li>
						<Link
							to="/newShipment"
							onClick={handleCollapse}
							className="nav-link"
						>
							<FaDolly /> <h3>Nuevo Envio</h3>
						</Link>
					</li>

					<li>
						<Link to="/shipments" onClick={handleCollapse} className="nav-link">
							<FaRegListAlt />
							<h3> Envios</h3>
						</Link>
					</li>

					<li className='mb-2'>
						<Link to="/contacts" onClick={handleCollapse} className="nav-link">
							<TiContacts />
							<h3> Libreta de Direcciones</h3>
						</Link>
					</li>

					{userInfo.userType === 'Gerente' ? (
						<li>
							<Link to="/balances" onClick={handleCollapse} className="nav-link mt-2">
								<FaChartBar />
								<h3> Balances</h3>
							</Link>
						</li>
					):null}
				</>
			) :
				userInfo.isAdmin ? (
					<>
						<li>
							<Link
								to="/AdminScreen/newShipment"
								onClick={handleCollapse}
								className="nav-link"
							>
								<FaDolly /> <h3>Nuevo Envio</h3>
							</Link>
						</li>

						<li>
							<Link
								to="/AdminScreen/shipments"
								onClick={handleCollapse}
								className="nav-link"
							>
								<FaRegListAlt />
								<h3> Envios</h3>
							</Link>
						</li>

						<li>
							<Link
								to="/AdminScreen/routes"
								onClick={handleCollapse}
								className="nav-link"
							>
								<FaClockRotateLeft />

								<h3>Histórico de rutas</h3>
							</Link>
						</li>
					</>
				) : null}

		
		</ul>
	);
}
