import axios from 'axios';
import classnames from 'classnames';
import React, { useContext, useEffect, useReducer, useState } from 'react';
import {
	Button,
	Card,
	Col,
	Container,
	Form,
	Modal,
	Row,
	Stack
} from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { BsArrowLeftCircle } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Store } from '../../Store';
import LoadingBox from '../../components/LoadingBox';
import MessageBox from '../../components/MessageBox';
import { getError, getStatus } from '../../utils';


const reducer = (state, action) => {
	switch (action.type) {
	case 'CREATE_REQUEST':
		return { ...state, loadingCreate: true };
	case 'CREATE_SUCCESS':
		return {
			...state,
			loadingCreate: false,
		};
	case 'CREATE_FAIL':
		return { ...state, loadingCreate: false, error: action.payload };
	case 'DELETE_REQUEST':
	default:
		return state;
	}
};
function AddMapIcons() {
	const { state, dispatch: ctxDispatch } = useContext(Store);
	const { userInfo } = state;
	const [{ loadingCreate, error }, dispatch] = useReducer(reducer, {
		loadingCreate: false,
		error: ''
	});
	
	const [name, setName] = useState('');
	const [image, setImage] = useState('');
	const [label, setLabel] = useState('');
	const [imageUrl, setImageUrl] = useState('');
	const [loadingPicture, setLoadingPicture] = useState(false);
	const [isActive, setIsActive] = useState(true);
	const [showModal, setShowModal] = useState(false);
	const handleClose = () => setShowModal(false);
	const handleShow = () => setShowModal(true);

	useEffect(() => {
		const fetchData = async () => {
			dispatch({ type: 'FETCH_REQUEST' });
			try {
				const result = await axios.get('/api/mapIcons', {
					headers: {
						Authorization: `Bearer ${userInfo ? userInfo.token : null}`,
					},
				});
				dispatch({ type: 'FETCH_SUCCESS', payload: result.data });
			} catch (error) {
				dispatch({ type: 'FETCH_FAIL', payload: getError(error) });
				if (getStatus(error) === 401) {
					ctxDispatch({ type: 'USER_SIGNOUT' });
					navigate('/signin');
					toast.error('Sesion expirada. Vuelve a ingresar.');
				} else {
					console.error(error);
					toast.error(error.message);
				}
			}
		};
	
		fetchData();

	}, []);

	const addMapIconHandler = async () => {
		try {
			dispatch({ type: 'CREATE_REQUEST' });
			await axios.post(
				'/api/mapIcons',
				{ name, image, label, isActive },
				{
					headers: {
						Authorization: `Bearer ${userInfo ? userInfo.token : null}`,
					},
				}
			);
			dispatch({ type: 'CREATE_SUCCESS' });
			toast.success('Icono agregado');
			navigate('/AdminScreen/Config');
		} catch (error) {
			dispatch({ type: 'CREATE_FAIL' });
			if (getStatus(error) === 401) {
				ctxDispatch({ type: 'USER_SIGNOUT' });
				navigate('/signin');
				toast.error('Sesion expirada. Vuelve a ingresar.');
			} else {
				console.error(error);
				toast.error(error.message);
			}
		}
	};
	let navigate = useNavigate();

	const uploadPicture = async (event) => {
		setLoadingPicture(true);
		const file = event.target.files[0];
		const formData = new FormData();
		let r = Math.random().toString(36).substring(7);
		formData.append('file', file, file.name.replaceAll(' ') + r);
		formData.append('documentId', image);
		const multiPart = { 'content-type': 'multipart/form-data' };
		try{
			const response = await axios.post(
				'/api/mapIcons/uploadMapIcon',
				formData,
				{
					...multiPart,
					headers: {
						Authorization: `Bearer ${userInfo ? userInfo.token : null}`,
					},
				}
			);
			setImage(response.data._id);
			setImageUrl(response.data.fileLink);
		} catch (error){
			console.error(error);
			setImage('');
			setImageUrl(null);
		}
		setLoadingPicture(false);

	};



	return (
		<div>
			<Modal size="lg" show={showModal} onHide={handleClose} animation={true}>
				<Modal.Header closeButton>
					<Modal.Title>Agregar Icono</Modal.Title>
				</Modal.Header>
				<Modal.Body>Seguro desea agregar una icono?</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleClose}>
              Cancelar
					</Button>
					<Button onClick={addMapIconHandler}>Confirmar</Button>
				</Modal.Footer>
			</Modal>
				
			<div className="large-container">

				<Helmet>
					<title>Nuevo Icono</title>
				</Helmet>

				<div
					className={classnames('container', {
						['admin-con']: userInfo && userInfo.isSuperAdmin,
					})}>

					{loadingCreate ? (
						<Row
							className="d-flex justify-content-center align-items-center spinner-row"
							style={{ minHeight: '100vh' }}
						>
							<LoadingBox className="col-1" />
						</Row>
					): error ? (
						<MessageBox variant="danger">{error}</MessageBox>
					) : (
					
						<div className="w-100 align-self-start" style={{ overflow: 'visible' }}>
							<Container fluid className="mt-3 mb-3" style={{ marginBottom: '50px'}}>
								<Row className="vh-90 d-flex justify-content-center align-items-center"  style={{width: '100%'}}>
									<Col md={8} lg={6} xs={12} className='w-100'>
										<div className="borderLine"></div>
										<Card className="shadow">
											<Card.Body>
												<div className="mb-3 mt-md-4">
													<h2 className="fw-bold mb-4 text-uppercase text-align-center ">
                      Nuevo Icono
													</h2>
													<div className="mb-3">
														<Form onSubmit={(e) => e.preventDefault()}>
															<Row>
																<Col sm={8}>
																	<Form.Group className="mb-3" controlId="name">
																		<Form.Label>Titulo</Form.Label>
																		<Form.Control onChange={(e) => setName(e.target.value)} />
																	</Form.Group>
																</Col>
																<Col>
																	<Form.Group className="mb-3" controlId="name">
																		<Form.Label>Tipo</Form.Label>
																		<Form.Select onChange={(e) => setLabel(e.target.value)}>
																			<option value="">Seleccione un tipo</option>
																			<option value="warehouse">Deposito</option>
																			<option value="delivered">Entregado</option>
																			<option value="inTransit">En Transito</option>
																		</Form.Select>
																	</Form.Group>
																</Col>
															
															</Row>

															<Row>
																<Col sm={8}>
																	<Form.Group className="mb-3" controlid="">
																		<Form.Label>Subir Icono</Form.Label>
																		<Form.Control
																			type="file"
																			onChange={(e)=> uploadPicture(e)}
																			name="mapIcon"
																		></Form.Control>
																	</Form.Group>
																</Col>
																<Col>
																	<div className="d-flex align-items-center h-100">
																		<Form.Check
																			checked={isActive}
																			defaultChecked={isActive}
																			name="activeId"
																			onChange={(e) => setIsActive(e.target.checked)}
																			label="Activo"
																			id="checkbox-activo"
																		/>
																	</div>
																</Col>
															</Row>
														

															<Form.Group className="mb-3 justify-content-center">
																{loadingPicture ?  (
																	<Row
																		className="d-flex justify-content-center align-items-center spinner-row"
																		style={{ minHeight: '100vh' }}
																	>
																		<LoadingBox className="col-1" />
																	</Row>
																) : 
																	<img
																		alt="product_photo"
																		src={imageUrl || '/images/emptyPhoto.png'}
																		style={{ maxWidth: '20rem', maxHeight: '10rem' }}
																	/>}
															</Form.Group>

															<Stack direction="horizontal" gap={10}>
																<Button id="goBack" onClick={() => navigate(-1)}>
																	<BsArrowLeftCircle /> Atras
																</Button>

																<Button
																	type="submit"
																	className="mx-auto"
																	onClick={handleShow}
																>
                            Agregar Icono
																</Button>
															</Stack>
														</Form>
													</div>
												
												
												</div>
											</Card.Body>
										</Card>
									</Col>
								</Row>
							</Container>
						</div>
					)}
				</div>
			</div>
		</div>
	);
}

export default AddMapIcons;
