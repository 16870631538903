import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import {
	Card,
	Form,
	Table
} from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { Store } from '../../Store.js';
import {
	getError,
	getStatus,
} from '../../utils.js';
import LoadingBox from '../LoadingBox';

import { toast } from 'react-toastify';
import './TablePendingShipments.css';



const TableVehicleDelivery = ({
	// onUpdateDelivery,
	onSelectedVehiclesChange,
	vehicles,
}) => {

	const navigate = useNavigate();
	const { dispatch: ctxDispatch } = useContext(Store);

	const [selectedVehicles, setSelectedVehicles] = useState([]);
	const [listedVehicles, setListedVehicles] = useState([]);
	const [loading, setLoading] = useState(true);
	const [deliveries, setDeliveries] = useState([]);
	const [loadingDeliveries, setLoadingDeliveries] = useState(true);

	const handleSelectAll = () => {
		if (
			selectedVehicles &&
      selectedVehicles.length === listedVehicles.length
		) {
			// If all shipments are already selected, deselect all
			setSelectedVehicles([]);
		} else {
			// If not all shipments are selected, select all
			setSelectedVehicles(listedVehicles);
		}
	};

	// Function to handle shipment selection
	const handleVehicleSelect = (selectedVehicle) => {
		// Check if the shipment is already selected
		const isSelected = selectedVehicles.includes(selectedVehicle);

		if (isSelected) {
			// If selected, remove it from the array
			setSelectedVehicles(
				selectedVehicles.filter(
					(vehicle) => vehicle._id !== selectedVehicle._id
				)
			);
		} else {
			// If not selected, add it to the array
			setSelectedVehicles([...selectedVehicles, selectedVehicle]);
		}
	};

	const changeDelivery = async (editableDelivery, vehicleRegNum) => {
		
		const vehicleToUpdate = listedVehicles.find(
			(vehicle) => vehicleRegNum === vehicle.registrationNumber
		);

		const vehicleIndex = listedVehicles.findIndex(
			(vehicle) => vehicleRegNum === vehicle.registrationNumber
		  );

		if(vehicleToUpdate){
			vehicleToUpdate.asignedDelivery = editableDelivery;
			if (vehicleIndex !== -1) {
				const updatedVehicles = [...listedVehicles];
				updatedVehicles[vehicleIndex] = vehicleToUpdate;
				setListedVehicles(updatedVehicles);
			}

			//update selectedVehicles
			const selectedVehicleToUpdate = selectedVehicles.find(
				(vehicle) => vehicleRegNum === vehicle.registrationNumber
			);
	
			const selectedVehicleIndex = selectedVehicles.findIndex(
				(vehicle) => vehicleRegNum === vehicle.registrationNumber
			  );

			  if(selectedVehicleToUpdate){
				selectedVehicleToUpdate.asignedDelivery = editableDelivery;
				if (selectedVehicleIndex !== -1) {
					const updatedVehicles = [...selectedVehicles];
					updatedVehicles[selectedVehicleIndex] = selectedVehicleToUpdate;
					setSelectedVehicles(updatedVehicles);
				}
	
	
			}
		}
	};


	const fetchDeliveries = async () => {
		try {
			setLoadingDeliveries(true);
			const result = await axios.get('/api/users/deliveries/getDeliveries');
			if (result) {
				setDeliveries(result.data);
				// await asignDelivery(result.data);
			}
			setLoadingDeliveries(false);
		} catch (error) {
			if (getStatus(error) === 401) {
				ctxDispatch({ type: 'USER_SIGNOUT' });
				navigate('/signin');
				toast.error('Sesion expirada. Vuelve a ingresar.');
			} else {
				console.error(error);
				toast.error(getError(error));
			}
		}
	};


	// Notify the parent component when selected shipments change
	useEffect(() => {
		const loadData = async() => {
			setLoading(true);
			setListedVehicles(vehicles);
			await fetchDeliveries();
			await onSelectedVehiclesChange(selectedVehicles);
			setLoading(false);
		};
		loadData();
	}, [onSelectedVehiclesChange]);

	return (
		<div>
			<Card>
				<div className="tableShipments-container">
					<Table responsive>
						{(!loading && !loadingDeliveries) ? (
							<>
								<thead>
									<tr>
										<th>
											<input
												type="checkbox"
												checked={
													selectedVehicles &&
                      listedVehicles &&
                      selectedVehicles.length === listedVehicles.length
												}
												onChange={handleSelectAll}
												// disabled={
												// 	vehicles &&
												// 	!vehicles.every((shipment) => shipment.checkable === true)
												//   }
											/>
										</th>
										<th>Vehicle</th>
										<th>Delivery</th>
							
										{/* Add other table headers */}
									</tr>
								</thead>
								<tbody>
									{listedVehicles.map((vehicle) => {
										return vehicle ? (
											<tr key={vehicle._id}>
												<td>
													<input
														type="checkbox"
														checked={selectedVehicles.includes(vehicle)}
														onChange={() => handleVehicleSelect(vehicle)}

													/>
												</td>
										
												<td>
													{vehicle.brand +
                                  ' ' +
                                  vehicle.model +
                                  ' - ' +
                                  vehicle.registrationNumber}
												</td>	
												<>
													<td>
														<Form.Select
															value={
																vehicle.asignedDelivery
																	? vehicle.asignedDelivery._id
																	: 'Sin Asignar'
															}
															className="border border-2 rounded mb-0"
															onChange={async (e) => {
																changeDelivery( e.target.value, vehicle.registrationNumber);
									
															}}
														>
											   <option key={null} value={'Sin Asignar'}>
        Sin Asignar
															</option>
															{deliveries.map((delivery) => (
																<option
																	key={delivery._id}
																	value={delivery._id}
																>
																	{delivery.name}
																</option>
															))}
														</Form.Select>
													</td>
												</>

											</tr>
										) : null;
									})}
								</tbody>
							</>  
						):  <LoadingBox></LoadingBox> }

					</Table>
				</div>
			</Card>
			{/* Add buttons or actions for selected items */}
		</div>
	);
};

export default TableVehicleDelivery;
