import axios from 'axios';
import React, { useEffect, useReducer, useState } from 'react';
import { Col, Container, Row, } from 'react-bootstrap';
import ClientCard from './Card';
import './Carousel.css';

const reducer = (state, action) => {
	switch (action.type) {
	case 'FETCH_SERVICES_REQUEST':
		return { ...state, loadingBannerFetch: true };
	case 'FETCH_SERVICES_SUCCESS':
		return {
			...state,
			services: action.payload,
			loadingFetch: false,
			itemQuantity: action.payload.length,
		};
	case 'FETCH_SERVICES_FAIL':
		return {
			...state,
			loadingBannerFetch: false,
			errorBannerFetch: action.payload,
		};
	default:
		return state;
	}
};

const ShowServices = () => {
	const [showComponent, setShowComponent] = useState(false);

	const [{ services }, dispatch] = useReducer(reducer, {
		services: [],
		loadingBannerFetch: true,
		errorBannerFetch: '',
	});

	useEffect(() => {
		if (showComponent === false) {
			const fetchData = async () => {
				try {
					dispatch({ type: 'FETCH_SERVICES_REQUEST' });
					const { data } = await axios.get('/api/services/isActiveService');
					dispatch({ type: 'FETCH_SERVICES_SUCCESS', payload: data });
					setShowComponent(true);
				} catch (err) {
					dispatch({ type: 'FETCH_SERVICES_FAIL', payload: err.message });
				}
			};
			fetchData();
		}
	}, []);

	return (
		<div className='w-100'>
			<div>
				<Container fluid>
					<div className='borderLine border-1 mb-2'></div>
					<h2 style={{ textAlign: 'center' }} className='mb-2'>Nuestros Servicios</h2>
					<div className='borderLine border-1 mb-2'></div>

					<Row className='mt-3' style={{ justifyContent: 'space-evenly', alignItems: 'center' }}>
						{services.map((service) =>
							service.image && service.image.fileLink ? (
								<Col xs={12} sm={6} md={4} lg={3} className='mb-4' key={service._id}>
									<ClientCard
										title={service.title}
										text={service.text}
										imgSrc={service.image.fileLink}
									/>
								</Col>
							) : null
						)}
					</Row>
				</Container>
			</div>
		</div>
	);
};

export default ShowServices;
