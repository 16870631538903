import React, { useContext, useEffect } from 'react';
import { Button, Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { BsCalculator } from 'react-icons/bs';
import { FaTruck } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import '../App.css';
import { Store } from '../Store';
import BootstrapCarouselComponent from '../components/Carousel';
import ShowServices from '../components/ShowServices.js';
import './Screens.css';

function HomeScreen() {
	const navigate = useNavigate();
	const { state } = useContext(Store);
	const { userInfo } = state;

	useEffect(() => {
		if (userInfo) {
			if (userInfo.isAdmin) {
				navigate('AdminScreen/shipments');
			} else {
				navigate('/shipments');
			}
		}
	}, [userInfo]);

	return (
		<div className='w-100'>
			<Helmet></Helmet>
			<div className='w-100'>
				<BootstrapCarouselComponent></BootstrapCarouselComponent>
				<Container fluid className='d-grid gap-4 p-4 w-100'>
					{userInfo ? (
						<Button
							className='calculatorButton'
							size='lg'
							onClick={() => {
								if (userInfo.isAdmin) {
									navigate('/AdminScreen/newShipment');
								} else {
									navigate('/newShipment');
								}
							}}
						>
              Nuevo envío <FaTruck />
						</Button>
					) : (
						<Button className='calculatorButton' size='lg' onClick={() => navigate('/newShipment')}>
              Calcular envío <BsCalculator />
						</Button>
					)}
				</Container>

				<Container fluid>
					<ShowServices className='mt-3'/>
		
				</Container>
			</div>
		</div>
	);
}
export default HomeScreen;
