import axios from 'axios';
import React, {
	useContext,
	useEffect,
	useMemo,
	useReducer,
	useState,
} from 'react';

import {
	Button,
	Col,
	Dropdown,
	DropdownButton,
	Row
} from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import {
	FaRoute
} from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Store } from '../../Store';
import LoadingBox from '../../components/LoadingBox';
import MessageBox from '../../components/MessageBox';
import GenerateRoutes from '../../components/ModalGenerateRoutes/GenerateRoutes';
import ShipmentListView from '../../components/ShipmentListView.js';
import { calculateDirectionsResponseUTILS } from '../../mapsUtils.js';
import { getError, sendNotification } from '../../utils';
import ConfirmShipmentModal from './ModalConfirmShipment/ModalConfirmShipment';

const reducer = (state, action) => {
	switch (action.type) {
	case 'FETCH_REQUEST':
		return { ...state, loading: true };

	case 'FETCH_CANCEL_REQUEST':
		return { ...state, loadingCancelation: true };
	case 'FETCH_CANCEL_SUCCESS':
		return {
			...state,
			loadingCancelation: false,
	
		};
	case 'FETCH_COMPLETE_REQUEST':
		return { ...state, loadingComplete: true };
	case 'FETCH_COMPLETE_SUCCESS':
		return {
			...state,
			loadingComplete: false,
	
		};

	case 'FETCH_PENDING_SUCCESS':
		return {
			...state,
			loading: false,
			pendingShipments: action.payload,
			pendingItemQuantity: action.payload.length,
		};
	case 'FETCH_ASIGNED_SUCCESS':
		return {
			...state,
			loading: false,

			asignedShipments: action.payload,
			asignedItemQuantity: action.payload.length,
		};
	case 'FETCH_COMPLETED_SUCCESS':
		return {
			...state,
			loading: false,

			completedShipments: action.payload,
			completedItemQuantity: action.payload.length,
		};
	case 'FETCH_CANCELLED_SUCCESS':
		return {
			...state,
			loading: false,

			cancelledShipments: action.payload,
			cancelledItemQuantity: action.payload.length,
		};

	case 'UPDATE_SUCCESS':
		return { ...state, loading: false };
	case 'FETCH_FAIL':
		return { ...state, loading: false, error: action.payload };
	case 'DELETE_REQUEST':
		return { ...state, loadingDelete: true };
	case 'DELETE_SUCCESS':
		return {
			...state,
			loadingDelete: false,
		};
	case 'DELETE_FAIL':
		return { ...state, loadingDelete: false };

	case 'DELETE_RESET':
		return { ...state, loadingDelete: false };
	case 'SHOW_GENERATE_ROUTES':
		return { ...state, generateRoutesModal: true };
	case 'HIDE_GENERATE_ROUTES':
		return { ...state, generateRoutesModal: false };
	case 'FETCH_TYPES_REQUEST':
		return { ...state, loadingTypes: true };
	case 'FETCH_TYPES_SUCCESS':
		return {
			...state,
			types: action.payload,
			loadingTypes: false,
		};
	case 'FETCH_TYPES_FAIL':
		return { ...state, loadingTypes: false, error: action.payload };
	default:
		return state;
	}
};

const baseStyle = {
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	padding: '20px',
	borderWidth: 2,
	borderRadius: 2,
	borderColor: '#eeeeee',
	borderStyle: 'dashed',
	backgroundColor: '#fafafa',
	color: '#bdbdbd',
	transition: 'border .3s ease-in-out',
};

function AdminShipmentList() {
	const [
		{
			loading,
			loadingDeliveries,
			loadingCancelation,
			loadingComplete,
			error,
			pendingShipments,
			pendingItemQuantity,
			asignedShipments,
			asignedItemQuantity,
			completedShipments,
			completedItemQuantity,
			cancelledShipments,
			cancelledItemQuantity,
			generateRoutesModal,
		},
		dispatch
	] = useReducer(reducer, {
		pendingShipments: [],
		asignedShipments: [],
		completedShipments: [],
		cancelledShipments: [],
		loading: true,
		loadingDeliveries: false,
		loadingCancelation: false,
		loadingComplete: false,
		generateRoutesModal: false,
		error: '',
		asignedItemQuantity: 0,
		pendingItemQuantity: 0,
		completedItemQuantity: 0,
		cancelledItemQuantity: 0,
	});

	const { state } = useContext(Store);
	const { userInfo } = state;
	const navigate = useNavigate();
	const isMobile =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    	navigator.userAgent
    );

	//state variables
	const [shipmentToConfirm, setShipmentToConfirm] = useState({});
	const [name, setName] = useState('');
	const [document, setDocument] = useState('');
	const [file, setFile] = useState(null);
	const [filtroEnvios, setFiltroEnvios] = useState('Asigned');
	const [title, setTitle] = useState('Envios Asignados ');
	const [updatedOrder, setUpdatedOrder] = useState(null);

	const myTitle = <h5 className="text-right d-inline-block">{title}</h5>;

	//confirmation modal
	const [showConfirmModal, setShowConfirmModal] = useState(false);
	const handleCloseConfirm = () => setShowConfirmModal(false);
	const handleShowConfirm = () => setShowConfirmModal(true);


	useMemo(() => ({ ...baseStyle }), []);

	useEffect( () => {
		const fetchData = async () => {
			if (userInfo && userInfo.isAdmin) {
				if(userInfo.isSuperAdmin){
					setFiltroEnvios('Pending');
					setTitle('Envios Pendientes ');
				}
				await getDebts();
			} else {
				navigate('/');
			}
		  }; 
		fetchData();
	}, [loadingCancelation, loadingComplete, updatedOrder]);


	//GET ALL DEBTS
	const getDebts = async () => {
		try {
			if(userInfo.isSuperAdmin){
				await getPendingShipments();
			}
			await getAsignedShipments();
			await getCompletedShipments();
			await getCancelledShipments();
		} catch (err) {
			dispatch({ type: 'FETCH_FAIL', payload: getError(err) });
		}
	};

	//PENDING SHIPMENTS
	const getPendingShipments = async () => {
		try {
			dispatch({ type: 'FETCH_REQUEST' });
			const status = 'Pending';
			const response = await axios.post(
				`/api/debts/shipmentByUser/byStatus/${status}`,
				{ user: userInfo }
			);
	
			dispatch({ type: 'FETCH_PENDING_SUCCESS', payload: response.data });
		} catch (err) {
			dispatch({ type: 'FETCH_FAIL', payload: getError(err) });
		}
	};

	//ASIGNED SHIPMENTS
	const getAsignedShipments = async () => {
		try {
			dispatch({ type: 'FETCH_REQUEST' });
			const status = 'Asigned';
			const response = await axios.post(
				`/api/debts/shipmentByUser/byStatus/${status}`,
				{ user: userInfo }
			);
			dispatch({ type: 'FETCH_ASIGNED_SUCCESS', payload: response.data });
		} catch (err) {
			dispatch({ type: 'FETCH_FAIL', payload: getError(err) });
		}
	};

	//COMPLETED SHIPMENTS
	const getCompletedShipments = async () => {
		try {
			dispatch({ type: 'FETCH_REQUEST' });
			const status = 'Completed';
			const response = await axios.post(
				`/api/debts/shipmentByUser/byStatus/${status}`,
				{ user: userInfo }
			);
			const orders =  response.data.map((debt)=> debt.shipment ? debt.shipment : null);
			dispatch({ type: 'FETCH_COMPLETED_SUCCESS', payload: orders });
		} catch (err) {
			dispatch({ type: 'FETCH_FAIL', payload: getError(err) });
		}
	};

	//CANCELLED SHIPMENTS
	const getCancelledShipments = async () => {
		try {
			dispatch({ type: 'FETCH_REQUEST' });
			const status = 'Cancelled';
			const response = await axios.post(
				`/api/debts/shipmentByUser/byStatus/${status}`,
				{ user: userInfo }
			);
			dispatch({ type: 'FETCH_CANCELLED_SUCCESS', payload: response.data });
		} catch (err) {
			dispatch({ type: 'FETCH_FAIL', payload: getError(err) });
		}
	};

	//COMPLETAR ENVIO
	const completeShipmentHandler = async (shipment) => {
		 setShipmentToConfirm(shipment);
		handleShowConfirm();
	};

	const completeShipment = async () => {
		if (name !== '' && document !== '') {
			let formData = null;
			let multiPart = null;
			if (file) {
				formData = new FormData();
				let r = Math.random().toString(36).substring(7);
				formData.append('file', file, file.name.replaceAll(' ') + r);
				formData.append('name', name);
				formData.append('document', document);
				multiPart = { 'content-type': 'multipart/form-data' };
			}
			try {
				dispatch({ type: 'FETCH_COMPLETE_REQUEST' });
			
				await sendNotification(shipmentToConfirm._id, userInfo.phoneNumber, 'Entregado');

				dispatch({ type: 'FETCH_COMPLETE_SUCCESS' });
				dispatch({ type: 'FETCH_REQUEST' });
				try {
					await axios.post(
						`/api/orders/completeShipment/${shipmentToConfirm._id}`,
						{
							...formData,
							name: name, 
							document: document, 
						},					{
							...multiPart,
							headers: {
								Authorization: `Bearer ${userInfo ? userInfo.token : null}`,
							},
						}

					);
					dispatch({ type: 'UPDATE_SUCCESS' });
				
					toast.success('Entrega confirmada');
					handleCloseConfirm();
					window.location.reload();
				} catch (error) {
					dispatch({ type: 'FETCH_FAIL', payload: getError(error) });

				}
			
			} catch (err) {
				toast.error(getError(err));
			}
		} else {
			if (name === '') {
				toast.error('Ingrese nombre del receptor');
			} else if (document === '') {
				toast.error('Ingrese documento del receptor');
			}
		}
	};

	async function detailsShipmentHandler(shipment) {
		navigate(`/AdminScreen/shipments/details/${shipment._id}`);
	}

	async function editStatusHandler(order, status) {
		const posibleStatus = [
			'Ingresado',
			'Confirmado',
			'Asignado',
			'Entregado',
			'En Camino',
			'Cancelacion Pendiente',
			'Cancelado',
		];
		if (posibleStatus.includes(status)) {
			try {
				await axios.put(`/api/orders/changeStatus/${order._id}`, {
					status,
					userInfo,
				});
				window.location.reload();
			} catch (err) {
				toast.error(getError(err));
			}
		}
	}

	async function cancelAsignedOrder(order, status){
		dispatch({ type: 'FETCH_CANCEL_REQUEST' });
		try {
			await updateAsignedOrder(order, status);
			dispatch({ type: 'FETCH_CANCEL_SUCCESS' });

		} catch (error) {
			dispatch({ type: 'FETCH_FAIL', payload: getError(error) });

		}

	}

	async function updateAsignedOrder(order, statusToUpdate) {
		
		
		if(order.status === 'Asignado'){
			//ENCONTRAR RUTA EN LA QUE SE ENCUENTRA
			try {
				//DIVIDO FUNCION
				//#1: ME DEVUELVE NUEVO ARRAY DE ORDERS, OLD DAILYROUTE Y ORIGIN
				//SI OPERACION ES COMPLETAR:
				
				if(statusToUpdate === 'Cancelado'){
					const response = await axios.get(`/api/orderDailyRouteRelation/removeOrderFromArray/${order._id}`, {
						headers: {
							Authorization: `Bearer ${userInfo ? userInfo.token : null}`,
						},
					}
					);
					const newArray = response.data.newArray;
					const oldDailyRoute = response.data.oldDailyRoute;
					const origin = response.data.origin;
				
					//#2: ELIMINO RUTA VIEJA
					await axios.delete(
						`/api/dailyRoutes/byId/${oldDailyRoute._id}`,
						{
										  headers: {
								Authorization: `Bearer ${userInfo ? userInfo.token : null}`,
										  },
						}
					);
					//#3: IF NEW ARRAY 
				
					if(newArray.length > 0){
					//#3.1: CALCULO NUEVA RUTA CON MAPUTILS --> manteniendo orden (optimizeWaypoints = false)
						const newRoute = await calculateDirectionsResponseUTILS(
							newArray,
							origin,
							false
						);


						//#3.2: CREO NUEVA RUTA, 
					
						const newDailyRoute = {
								  routeDate: oldDailyRoute.date,
								  createdBy: userInfo,
								  sortedOrders: newRoute.sortedOrders,
								  sortedOrderInfo: newRoute.sortedOrderInfo,
								  vehicle: newRoute.vehicle,
								  time: newRoute.totalTime,
								  distance: newRoute.totalDistance,
								  originMarker: newRoute.originMarker,
								  asignedDelivery: oldDailyRoute.asignedDelivery,

						};

						await axios.post(
								  '/api/dailyRoutes/createDailyRoute',
								  {
								dailyRoute: newDailyRoute,
								  }
								  );
					}
				
					//#4: CAMBIO DE ESTADO 
					if(statusToUpdate === 'Cancelado'){
						await axios.put(`/api/orders/changeStatus/${order._id}`, {
							statusToUpdate,
							userInfo,
							  });

					}
				
					//#5 ENVIAR NOTIFICACION:
					await sendNotification(order._id, userInfo.phoneNumber, statusToUpdate);
					window.location.reload();
				}		
			} catch (err) {
				dispatch({ type: 'FETCH_FAIL', payload: getError(err) });
			}}
	}

	async function confirmChanges(order, type) {
		const zoneId= order.route.destinationAddress.zone._id;
		const newPrice = await getPrice(zoneId, type);
		if(newPrice){
			  setUpdatedOrder(await updateOrder(order._id, type, newPrice));
			  toast.success('Tipo de envio actualizado correctamente.');
		} else{
			toast.error('No es posible actualizar el tipo de envio ya que no esta cotizado');
		}
	}


	//get prices
	async function getPrice(selectedZoneId, selectedTypeId) {
		try {
			const relation = await axios.get(`/api/typeZoneRelation/price/${selectedZoneId}/${selectedTypeId}`);
			if(relation.data){
				return relation.data.price;
			} else{
				return null;
			}

		} catch (err) {
			dispatch({ type: 'FETCH_FAIL', payload: getError(err) });
		}
	}

	//UPDATE ORDER
	async function updateOrder(orderId, newType, newPrice) {
		try {
			const updOrder = await axios.put(`/api/orders/changeType/${orderId}`,
				{
					newType, newPrice
				});
			return (updOrder.data);
		} catch (err) {
			dispatch({ type: 'FETCH_FAIL', payload: getError(err) });
		}
	}

	const onDrop = (acceptedFiles) => {
		setFile(acceptedFiles);
	};

	//#region MODALS

	async function generateRoutes() {
		dispatch({ type: 'SHOW_GENERATE_ROUTES' });
	}
	//#endregion

	return loading || loadingDeliveries ? (
		<Row
			className="d-flex justify-content-center align-items-center spinner-row"
			style={{ minHeight: '100vh' }}
		>
			<LoadingBox className="col-1" />
		</Row>
	) : error ? (
		<MessageBox variant="danger">{error}</MessageBox>
	) : (
		<>
			<div>
				<ConfirmShipmentModal
					show={showConfirmModal}
					onHide={handleCloseConfirm}
					onConfirm={completeShipment}
					isMobile={isMobile}
					name={name}
					setName={setName}
					document={document}
					setDocument={setDocument}
					file={file}
					setFile={setFile}
					onDrop={onDrop}
				/>
			</div>

			<div>
				<Helmet>
					<title>Lista de Envios</title>
				</Helmet>
				{userInfo && userInfo.isSuperAdmin ? (
					<GenerateRoutes
						show={generateRoutesModal}
						onHide={() => dispatch({ type: 'HIDE_GENERATE_ROUTES' })}
						onSuccess={() => dispatch({ type: 'RELOAD_CONTACTS' })}
					/>
				) : null}
				
				<div className="container admin-con">
					<div className="w-100">
						<div
							style={{
								display: 'flex',
								justifyContent: 'right',
								alignItems: 'center',
							}}
						>
							<Button
								className="btn btn-dark m-1 mt-2 fixed-right"
								value="Generar Ruteo"
								onClick={() => generateRoutes()}
							>
								<span>
									<FaRoute /> Generar Rutas
								</span>
							</Button>
						</div>
						<Row className="mb-4">
							<Col className="d-flex justify-content-center align-items-center ">
								<DropdownButton
									className="btn title-dropdown"
									align="end"
									title={myTitle}
								>
									{userInfo && userInfo.isSuperAdmin ? (
										<Dropdown.Item
											onClick={() => {
												setFiltroEnvios('Pending');
												setTitle('Envios Pendientes ');
											}}
										>
                    Envios Pendientes
										</Dropdown.Item>

									): null }
									<Dropdown.Item
										onClick={() => {
											setFiltroEnvios('Asigned');
											setTitle('Envios Asignados ');
										}}
									>
                    Envios Asignados
									</Dropdown.Item>
									<Dropdown.Item
										onClick={() => {
											setFiltroEnvios('Completed');
											setTitle('Envios Completados ');
										}}
									>
                    Envios Completados
									</Dropdown.Item>
									<Dropdown.Item
										onClick={() => {
											setFiltroEnvios('Cancelled');
											setTitle('Envios Cancelados ');
										}}
									>
                    Envios Cancelados
									</Dropdown.Item>
								</DropdownButton>
							</Col>
						</Row>

						<React.Fragment>
							{filtroEnvios === 'Pending' ? (
								<ShipmentListView
									shipmentList={pendingShipments}
									itemQuantity={pendingItemQuantity}
									shipmentStatus={'Pending'}
									title = {title}
									isLoading={true}
									onConfirmChanges={confirmChanges}
									onCompleteShipment={completeShipmentHandler}
									onEditStatus={editStatusHandler}
									isList={false}
								/>
								
							) : null}

							{filtroEnvios === 'Asigned' && !loadingCancelation && !loadingComplete ? (
								<ShipmentListView
									shipmentList={asignedShipments}
									itemQuantity={asignedItemQuantity}
									shipmentStatus={'Asigned'}
									title = {title}
									isLoading={true}
									onConfirmChanges={confirmChanges}
									onCompleteShipment={completeShipmentHandler}
									onEditStatus={cancelAsignedOrder}
									isList={false}

								/>
						
							) : null}

							{filtroEnvios === 'Completed' ? (
								<ShipmentListView
									shipmentList={completedShipments}
									itemQuantity={completedItemQuantity}
									shipmentStatus={'Completed'}
									title = {title}
									isLoading={true}
									onDetailsShipment={detailsShipmentHandler}
									isList={true}
								/>
							) : null}

							{filtroEnvios === 'Cancelled' ? (
								<ShipmentListView
									shipmentList={cancelledShipments}
									itemQuantity={cancelledItemQuantity}
									shipmentStatus={'Cancelled'}
									title = {title}
									isLoading={true}
									onDetailsShipment={detailsShipmentHandler}
									isList={true}
								/>
							) : null}
						</React.Fragment>
					</div>
				</div>
			</div>
		</>
	);
}
export default AdminShipmentList;



