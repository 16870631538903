import axios from 'axios';
import moment from 'moment';
import React, { useContext, useEffect, useReducer } from 'react';
import { Accordion, Button, Card, Col, Row, Stack } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { Helmet } from 'react-helmet-async';
import { BsArrowLeftCircle } from 'react-icons/bs';
import { useNavigate, useParams } from 'react-router-dom';
import { Store } from '../../Store';
import LoadingBox from '../../components/LoadingBox';
import { getError } from '../../utils';

import { FaPaperclip } from 'react-icons/fa';
import './ShipmentDetails.css';

const reducer = (state, action) => {
	switch (action.type) {
	case 'FETCH_REQUEST':
		return { ...state, loading: true };
	case 'FETCH_SUCCESS':
		return { ...state, order: action.payload, loading: false };
	case 'FETCH_FAIL':
		return { ...state, loading: false, error: action.payload };
	default:
		return state;
	}
};

function ShipmentDetails() {
	const params = useParams();
	const navigate = useNavigate();
	const { orderId } = params;
	const [{ loading, order }, dispatch] = useReducer(reducer, {
		order: {},
		loading: true,
		error: '',
	});
	const { state } = useContext(Store);
	const { userInfo } = state;

	useEffect(() => {
		const fetchData = async () => {
			dispatch({ type: 'FETCH_REQUEST' });
			try {
				const result = await axios.get(`/api/orders/${orderId}`);
				dispatch({ type: 'FETCH_SUCCESS', payload: result.data });
			} catch (error) {
				dispatch({ type: 'FETCH_FAIL', payload: getError(error) });
			}
		};

		fetchData();
	}, [orderId]);

	async function downloadFile(file) {
		if (file) {
			window.open(file.fileLink, '_blank', 'noopener,noreferrer');
		}
	}

	if  (!userInfo) return null;

	return (
		<div>
			{loading && <LoadingBox></LoadingBox>}
			{/* CONFIRM MODAL / BAR LOADER / ERRORS*/}

			<div>
				<Helmet>
					<title>Shipment Details</title>
				</Helmet>

				<div className="container admin-con d-flex align-items-center justify-content-center">

					<div className="w-100">
						<Card className="shadow mt-5">
							<Card.Body>
								<div className="mb-3 mt-md-4">
									<h2 className="fw-bold mb-4 text-uppercase text-align-center ">
                    Detalles del Envío
									</h2>
									<div className="mb-3">
										{order ? (
											<Form onSubmit={(e) => e.preventDefault()}>
												<Accordion className="bg-transparent ml-1 mb-3">
													<Accordion.Item>
														<Accordion.Header
															style={{
																display: 'flex',
																alignItems: 'center',
																justifyContent: 'space-between',
																justifyItems: 'center',
															}}
														>
															<div
																style={{
																	display: 'flex',
																	justifyContent: 'space-between',
																	width: '100%',
																	alignItems: 'center',
																}}
															>
																<label>Info general</label>
															</div>
														</Accordion.Header>
														<Accordion.Body className="p-0 m-2">
															<Row>
																<Col>
																	<Form.Group className="mb-3" controlid="name">
																		<Form.Label>Cuenta</Form.Label>
																		<Form.Control
																			type="text"
																			required
																			disabled
																			value={
																				order.account
																					? order.account.name
																					: null
																			}
																		></Form.Control>
																	</Form.Group>
																</Col>
																<Col>
																	<Form.Group className="mb-3" controlid="name">
																		<Form.Label>Creado por</Form.Label>
																		<Form.Control
																			type="text"
																			required
																			disabled
																			value={
																				order
																					? order.createdBy
																						? order.createdBy.name
																						: null
																					: null
																			}
																		></Form.Control>
																	</Form.Group>
																</Col>
															</Row>
															<Row>
																<Col>
																	<Form.Group className="mb-3" controlid="name">
																		<Form.Label>Tipo de Envio</Form.Label>
																		<Form.Control
																			type="text"
																			required
																			disabled
																			value={
																				order
																					? order.type
																						? order.type.name
																						: null
																					: null
																			}
																		></Form.Control>
																	</Form.Group>
																</Col>
																<Col>
																	<Form.Group
																		className="mb-3"
																		controlid="amount"
																	>
																		<Form.Label>Monto Total</Form.Label>
																		<Form.Control
																			type="number"
																			required
																			disabled
																			value={Math.round(order.price)}
																		></Form.Control>
																	</Form.Group>
																</Col>
															</Row>
															<Row>
																<Col>
																	<Form.Group
																		className="mb-3"
																		controlid="balance"
																	>
																		<Form.Label>Estado</Form.Label>
																		<Form.Control
																			type="text"
																			required
																			disabled
																			value={order ? order.status : null}
																		></Form.Control>
																	</Form.Group>
																</Col>

																<Col>
																	<Form.Group className="mb-3">
																		<Form.Label>
                                      Cantidad de bultos:{' '}
																		</Form.Label>
																		<Form.Control
																			type="text"
																			required
																			disabled
																			value={
																				order.additionalDetails
																					? order.additionalDetails.itemQuantity
																					: null
																			}
																		></Form.Control>
																	</Form.Group>
																</Col>
															</Row>

															<Row>
																<Col>
																	<Form.Group
																		className="mb-3"
																		controlid="createdAt"
																	>
																		<Form.Label>Fecha de Orden</Form.Label>
																		<Form.Control
																			type="text"
																			required
																			disabled
																			value={
																				order
																					? moment(
																						new Date(order.orderDate)
																					).format('DD/MM/YY')
																					: null
																			}
																		></Form.Control>
																	</Form.Group>
																</Col>

																<Col>
																	<Form.Group
																		className="mb-3"
																		controlid="balance"
																	>
																		<Form.Label>Fecha Solicitada</Form.Label>
																		<Form.Control
																			type="text"
																			required
																			disabled
																			value={
																				order
																					? order.shipmentDate
																						? moment(
																							new Date(order.shipmentDate)
																						).format('DD/MM/YY')
																						: null
																					: null
																			}
																		></Form.Control>
																	</Form.Group>
																</Col>
															</Row>
														</Accordion.Body>
													</Accordion.Item>
												</Accordion>
												<Accordion className="bg-transparent ml-1 mb-3">
													<Accordion.Item>
														<Accordion.Header
															style={{
																display: 'flex',
																alignItems: 'center',
																justifyContent: 'space-between',
																justifyItems: 'center',
															}}
														>
															<div
																style={{
																	display: 'flex',
																	justifyContent: 'space-between',
																	width: '100%',
																	alignItems: 'center',
																}}
															>
																<label>Direcciones</label>
															</div>
														</Accordion.Header>
														<Accordion.Body className="p-0 m-2">
															{order.route ? (
																<React.Fragment>
																	<Row>
																		<Form.Group
																			className="mb-3"
																			controlid="name"
																		>
																			<Form.Label>
                                        Direccion de Origen
																			</Form.Label>
																			<Form.Control
																				type="text"
																				required
																				disabled
																				value={
																					order.route.originAddress ? order.route.originAddress.address : null
																				}
																			></Form.Control>
																		</Form.Group>
																	</Row>

																	<Row>
																		<Form.Group
																			className="mb-3"
																			controlid="name"
																		>
																			<Form.Label>
                                        Direccion de Destino
																			</Form.Label>
																			<Form.Control
																				type="text"
																				required
																				disabled
																				value={
																					order.route.destinationAddress ? order.route.destinationAddress.address : null
																				}
																			></Form.Control>
																		</Form.Group>
																	</Row>

																	<Row>
																		<Col>
																			<Form.Group
																				className="mb-3"
																				controlid="name"
																			>
																				<Form.Label>Zona de Origen</Form.Label>
																				<Form.Control
																					type="text"
																					required
																					disabled
																					value={
																						order.route.originAddress.zone.name
																					}
																				></Form.Control>
																			</Form.Group>
																		</Col>

																		<Col>
																			<Form.Group
																				className="mb-3"
																				controlid="name"
																			>
																				<Form.Label>Zona de Destino</Form.Label>
																				<Form.Control
																					type="text"
																					required
																					disabled
																					value={
																						order.route.destinationAddress.zone
																							.name
																					}
																				></Form.Control>
																			</Form.Group>
																		</Col>
																	</Row>
																</React.Fragment>
															) : null}
														</Accordion.Body>
													</Accordion.Item>
												</Accordion>
												<Accordion className="bg-transparent ml-1 mb-3">
													<Accordion.Item>
														<Accordion.Header
															style={{
																display: 'flex',
																alignItems: 'center',
																justifyContent: 'space-between',
																justifyItems: 'center',
															}}
														>
															<div
																style={{
																	display: 'flex',
																	justifyContent: 'space-between',
																	width: '100%',
																	alignItems: 'center',
																}}
															>
																<label>Cliente</label>
															</div>
														</Accordion.Header>
														<Accordion.Body className="p-0 m-2">
															{order.additionalDetails && (
																<>
																	<Row>
																		<Col>
																			<Form.Group className="mb-3">
																				<Form.Label>Nombre cliente</Form.Label>
																				<Form.Control
																					type="text"
																					required
																					disabled
																					value={
																						order.additionalDetails.clientName
																					}
																				></Form.Control>
																			</Form.Group>
																		</Col>
																		<Col>
																			<Form.Group
																				className="mb-3"
																				controlid="name"
																			>
																				<Form.Label>
                                          Teléfono de Contacto:{' '}
																				</Form.Label>
																				<Form.Control
																					type="text"
																					required
																					disabled
																					value={
																						order.additionalDetails.clientPhone
																					}
																				></Form.Control>
																			</Form.Group>
																		</Col>
																	</Row>
																	<Row>
																		<Form.Group
																			className="mb-3"
																			controlId="order-comments"
																		>
																			<Form.Label>Comentarios: </Form.Label>
																			<Form.Control
																				as="textarea"
																				type="text"
																				required
																				disabled
																				value={order.additionalDetails.comments}
																			></Form.Control>
																		</Form.Group>
																	</Row>
																</>
															)}
														</Accordion.Body>
													</Accordion.Item>
												</Accordion>

												{order.status === 'Entregado' ||
                        order.status === 'Cancelado' ? (
														<Accordion className="bg-transparent ml-1 mb-3">
															<Accordion.Item>
																<Accordion.Header
																	style={{
																		display: 'flex',
																		alignItems: 'center',
																		justifyContent: 'space-between',
																		justifyItems: 'center',
																	}}
																>
																	<div
																		style={{
																			display: 'flex',
																			justifyContent: 'space-between',
																			width: '100%',
																			alignItems: 'center',
																		}}
																	>
																		{order.status === 'Entregado' ? (
																			<label>Datos de Entrega</label>
																		) : order.status === 'Cancelado' ? (
																			<label>Datos de Cancelacion</label>
																		) : null}
																	</div>
																</Accordion.Header>
																<Accordion.Body className="p-0 m-2">
																	{order ? (
																		order.status === 'Entregado' ? (
																			<React.Fragment>
																				<Row>
																					<Col>
																						<Form.Group
																							className="mb-3"
																							controlid="name"
																						>
																							<Form.Label>
                                              Fecha de Entrega
																							</Form.Label>
																							<Form.Control
																								type="text"
																								required
																								disabled
																								value={
																									order
																										? moment(
																											new Date(
																												order.deliveredDate
																											)
																										).format('DD/MM/YY')
																										: null
																								}
																							></Form.Control>
																						</Form.Group>
																					</Col>

																					<Col>
																						<Form.Group
																							className="mb-3"
																							controlid="name"
																						>
																							<Form.Label>
                                              Responsable de Entrega
																							</Form.Label>
																							<Form.Control
																								type="text"
																								required
																								disabled
																								value={
																									order.deliveredBy
																										? order.deliveredBy.name
																										: null
																								}
																							></Form.Control>
																						</Form.Group>
																					</Col>
																				</Row>

																				<Row>
																					<Col>
																						<Form.Group
																							className="mb-3"
																							controlid="name"
																						>
																							<Form.Label>Vehiculo</Form.Label>
																							<Form.Control
																								type="text"
																								required
																								disabled
																								value={
																									order.deliveryVehicle
																										? order.deliveryVehicle
																											.brand +
                                                    ' ' +
                                                    order.deliveryVehicle
                                                    	.model +
                                                    ' - ' +
                                                    order.deliveryVehicle
                                                    	.registrationNumber
																										: null
																								}
																							></Form.Control>
																						</Form.Group>
																					</Col>
																				</Row>

																				<Row>
																					<Col>
																						<Form.Group
																							className="mb-3"
																							controlid="name"
																						>
																							<Form.Label>
                                              Nombre Consignatario
																							</Form.Label>
																							<Form.Control
																								type="text"
																								required
																								disabled
																								value={order.recipientName}
																							></Form.Control>
																						</Form.Group>
																					</Col>

																					<Col>
																						<Form.Group
																							className="mb-3"
																							controlid="name"
																						>
																							<Form.Label>
                                              Documento Consignatario
																							</Form.Label>
																							<Form.Control
																								type="text"
																								required
																								disabled
																								value={order.recipientDoc}
																							></Form.Control>
																						</Form.Group>
																					</Col>
																				</Row>
																				<Row>
																					<Col>
																						<Form.Group
																							className="mb-2 mt-1"
																							controlid="name"
																						>
																							<Form.Label> </Form.Label>
																							<Button
																								variant="link"
																								onClick={() =>
																									downloadFile(
																										order.recipientFile
																									)
																								}
																							>
																								<FaPaperclip /> Ver Comprobante
																							</Button>
																						</Form.Group>
																					</Col>
																				</Row>
																			</React.Fragment>
																		) : order.status === 'Cancelado' ? (
																			<Row>
																				<Col>
																					<Form.Group
																						className="mb-3"
																						controlid="name"
																					>
																						<Form.Label>
                                            Fecha de Cancelacion
																						</Form.Label>
																						<Form.Control
																							type="text"
																							required
																							disabled
																							value={
																								order
																									? moment(
																										new Date(
																											order.cancelledDate
																										)
																									).format('DD/MM/YY')
																									: null
																							}
																						></Form.Control>
																					</Form.Group>
																				</Col>

																				<Col>
																					<Form.Group
																						className="mb-3"
																						controlid="name"
																					>
																						<Form.Label>
                                            Responsable de Cancelacion
																						</Form.Label>
																						<Form.Control
																							type="text"
																							required
																							disabled
																							value={
																								order.cancelledBy
																									? order.cancelledBy.name
																									: null
																							}
																						></Form.Control>
																					</Form.Group>
																				</Col>
																			</Row>
																		) : null
																	) : null}
																</Accordion.Body>
															</Accordion.Item>
														</Accordion>
													) : null}

												<Stack direction="vertical">
													<Button
														className="justify-content-left"
														id="goBack"
														onClick={() => navigate(-1)}
													>
														<BsArrowLeftCircle /> Atras
													</Button>
												</Stack>
											</Form>
										) : (
											<div>
												<LoadingBox />
											</div>
										)}
									</div>
								</div>
							</Card.Body>
						</Card>
					</div>
				</div>
			</div>
		</div>
	);
}
export default ShipmentDetails;
